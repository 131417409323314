const AuthType = {
  SET_USER: "SET_USER",
  RESET_AUTH: "RESET_AUTH",
};

export interface User {
  id: number;
  name: string;
  mobile: string;
  email: string;
  created_at: any;
}

export interface LogIn {
  email: string;
  password: string;
}

export default AuthType;
