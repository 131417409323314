import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SalaryAction from "../stores/action/salaryAction";
import { Link } from "react-router-dom";
import { CHEQUE_IMG } from "../utils/Constants";

function PaymentModal({
  modal,
  setModal,
  getSalaryRequest,
  selectedRows,
  setSelectedRows,
  setSelectAll,
  totalAmount,
  totalGstAmount,
  totalServiceFee,
  totalAmountReceivable,
}: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<any>();

  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  // Profile Details State
  const [paymentSlip, setPaymentSlip] = useState<File | string>("");
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handlePaymentImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setPaymentSlip(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      await dispatch(
        SalaryAction.salaryPaymentSlip({
          paymentSlip,
          employeeId: selectedRows,
          totalAmount,
          totalGstAmount,
          totalServiceFee,
          totalAmountReceivable,
        }),
      );
      setLoading(false);
      setModal(false);
      getSalaryRequest();
      setSelectedRows([]);
      setPaymentSlip("");
      setPreviewUrl("");
      setSelectAll("");
    } catch (error) {
      console.error("Error updating payment:", error);
      setLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <form
          className="needs-validation"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Payment Slip</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {/* Bank Details Section */}
              <div className="text-center mb-3">
                <h3 className="text-success">
                  <i>Payable Amount : Rs. {totalAmountReceivable}</i>
                </h3>
                <img src={CHEQUE_IMG} alt="Bank Cheque" className="mb-2" />
                <h5 className="font-bold text-dark">
                  <i>Bank Account Details</i>
                </h5>
              </div>
              <div
                className="border rounded p-3 mb-3"
                style={{ background: "#dcdcff" }}
              >
                <div className={"d-flex justify-content-between"}>
                  <p className={"font-bold text-dark m-0"}>Account Holder</p>
                  <p className={"m-0 text-dark"}>
                    ABHHYAM SECURE PRIVATE LIMITED
                  </p>
                </div>
                <div className={"d-flex justify-content-between"}>
                  <p className={"font-bold text-dark m-0"}>Account Number</p>
                  <p className={"m-0 text-dark"}>5020 0089 5667 31</p>
                </div>
                <div className={"d-flex justify-content-between"}>
                  <p className={"font-bold text-dark m-0"}>IFSC Code</p>
                  <p className={"m-0 text-dark"}>HDFC0000886</p>
                </div>
                <div className={"d-flex justify-content-between"}>
                  <p className={"font-bold text-dark m-0"}>Bank Name</p>
                  <p className={"m-0 text-dark"}>HDFC Bank</p>
                </div>
                <div className={"d-flex justify-content-between"}>
                  <p className={"font-bold text-dark m-0"}>Branch Name</p>
                  <p className={"m-0 text-dark"}>ROHINI, SECTOR 8</p>
                </div>
              </div>
              {/* Upload Payment Slip */}
              <div className="col-md-12">
                <label className="fw-bold">Upload Payment Slip</label>
                <div className="form-group">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handlePaymentImageChange}
                    accept="image/png, image/jpeg"
                    required
                    ref={fileInputRef}
                  />
                </div>
                {previewUrl && (
                  <div className="mt-2">
                    <Link to={`${previewUrl}`} target={"_blank"}>
                      <img
                        src={`${previewUrl}`}
                        width={200}
                        height={200}
                        className="border rounded"
                        alt="Payment Slip"
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                disabled={loading}
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "PROCEED"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PaymentModal;
