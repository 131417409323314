import { useDispatch, useSelector } from "react-redux";
import WebLayout from "../../layout/WebLayout";
import { RootState } from "../../stores";
import React, { useCallback, useEffect, useState } from "react";
import ProfileAction from "../../stores/action/profileAction";
import UpdateProfile from "../../components/Profile/UpdateProfile";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import ImageComponent from "../../components/ImageComponent";

function EmployeeProfile() {
  const dispatch = useDispatch<any>();
  const { id } = useParams<any>();

  const [modal, setModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("profile");

  const { profileData } = useSelector((state: RootState) => state.profile);

  const getProfileData = useCallback(async () => {
    await dispatch(ProfileAction.getProfile({ id: id ? Number(id) : 0 }));
  }, [dispatch, id]);

  // Initialize all state variables with empty strings
  const [name, setName] = useState<string>("");
  const [fatherName, setFatherName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [pinCode, setPinCode] = useState<string>("");
  const [presentAddress, setPresentAddress] = useState<string>("");
  const [permanentAddress, setPermanentAddress] = useState<string>("");
  const [accountHolderName, setAccountHolderName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [ifscCode, setIFSCCode] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");

  useEffect(() => {
    // Set state variables with fallback to empty strings
    setName(profileData?.name || "");
    setFatherName(profileData?.f_name || "");
    setEmail(profileData?.email || "");
    setMobile(profileData?.mobile || "");
    setStateName(profileData?.sName || "");
    setDistrict(profileData?.dName || "");
    setPinCode(profileData?.pincode || "");
    setPresentAddress(profileData?.present_address || "");
    setPermanentAddress(profileData?.permanent_address || "");
    setAccountHolderName(profileData?.account_holder_name || "");
    setAccountNumber(profileData?.account_number || "");
    setIFSCCode(profileData?.ifsc_code || "");
    setBankName(profileData?.bank_name || "");
    setBranchName(profileData?.branch_name || "");
  }, [profileData]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const openModal = (type: string) => {
    setModal(true);
    setModalType(type);
  };

  return (
    <WebLayout pageName={"EmployeeProfile"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"Profile"} subTitle={"Employee Profile"} />
            </div>
          </div>
          <div className="card card-body">
            <div className="container-fluid rounded bg-white">
              <div className="row">
                {/* Profile Section */}
                <div className="col-md-3 border-right">
                  <div className="d-flex flex-column align-items-center text-center p-3 py-1">
                    {profileData?.profile_img && (
                      <Link to={profileData.profile_img} target="_blank">
                        <img
                          className="p-2 mt-5"
                          width="200"
                          src={profileData.profile_img}
                          alt="Profile"
                        />
                      </Link>
                    )}
                    <span className="font-weight-bold text-dark">{name}</span>
                    <span className="text-black-50">{email}</span>
                    <p className={"m-0"}>
                      <b>EMA Id :</b>{" "}
                      {profileData?.emp_no ? profileData.emp_no : "N/A"}
                      <br />
                      <b>ASL Id :</b>{" "}
                      {profileData?.emp_id?.includes("AST")
                        ? "N/A"
                        : profileData?.emp_id}
                      <br />
                      <b>EMC Employee Id :</b>{" "}
                      {profileData?.employeeId
                        ? profileData?.employeeId
                        : "N/A"}
                    </p>
                    <h2 className="text-success">
                      Gross Salary : ₹ {profileData?.gross_salary}
                    </h2>
                  </div>
                </div>

                {/* Personal Details Section */}
                <div className="col-md-3 border-right">
                  <div className="p-1 py-1">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4 className="text-right text-dark">Personal Details</h4>
                      <UpdateProfile
                        modal={modal}
                        setModal={setModal}
                        modalType={modalType}
                        getProfileData={getProfileData}
                        profileData={profileData}
                      />
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => openModal("profile")}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Father Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={fatherName}
                          onChange={(e) => setFatherName(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Email Id</label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Mobile Number</label>
                        <input
                          type="tel"
                          className="form-control"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">State</label>
                        <input
                          type="text"
                          className="form-control"
                          value={stateName}
                          onChange={(e) => setStateName(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">District</label>
                        <input
                          type="text"
                          className="form-control"
                          value={district}
                          onChange={(e) => setDistrict(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Pin Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={pinCode}
                          onChange={(e) => setPinCode(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Present Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={presentAddress}
                          onChange={(e) => setPresentAddress(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Permanent Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={permanentAddress}
                          onChange={(e) => setPermanentAddress(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Bank Details Section */}
                <div className="col-md-3 border-right">
                  <div className="p-1 py-1">
                    <div className="d-flex justify-content-between align-items-center experience">
                      <h4 className="text-right text-dark">Bank Details</h4>
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => openModal("bank")}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">Account Holder Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={accountHolderName}
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="labels">Account Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="labels">Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="labels">IFSC Code</label>
                        <input
                          type="text"
                          className="form-control"
                          value={ifscCode}
                          onChange={(e) => setIFSCCode(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="labels">Branch Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={branchName}
                          onChange={(e) => setBranchName(e.target.value)}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* KYC Details Section */}
                <div className="col-md-3">
                  <div className="p-1 py-1">
                    <div className="d-flex justify-content-between align-items-center experience">
                      <h4 className="text-right text-dark">KYC Details</h4>
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => openModal("kyc")}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="row mt-3">
                      {/* PAN Details */}

                      <article className="col-md-12 col-sm-12 portfolio-item apps">
                        <div className="portfolio-thumb in">
                          <Link to={profileData?.pan_img} target="_blank">
                            <ImageComponent
                              sourceImage={profileData?.pan_img}
                              width={200}
                              height={200}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </div>
                        <h5 className="text-center mt-2 text-dark">
                          PAN No. {profileData?.pan_no || "N/A"}
                        </h5>
                      </article>

                      {/* Aadhaar Details */}

                      <article className="col-md-12 col-sm-12 portfolio-item apps mt-4">
                        <div className="portfolio-thumb in">
                          <Link to={profileData?.aadhar_img} target="_blank">
                            <ImageComponent
                              sourceImage={profileData?.aadhar_img}
                              width={200}
                              height={200}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </div>
                        <h5 className="text-center mt-2 text-dark">
                          Aadhaar No. {profileData?.aadhar_no || "N/A"}
                        </h5>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployeeProfile;
