import React, { useCallback, useEffect, useRef, useState } from "react";
import Step1Form from "./Steps/kyc/Step1Form";
import Step2Form from "./Steps/kyc/Step2Form";
import Step3Form from "./Steps/kyc/Step3Form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores";
import EmployerAction from "../stores/action/employerAction";
import WebAction from "../stores/action/webAction";

const StepModal = () => {
  const firstRender = useRef(true);
  const dispatch = useDispatch<any>();
  const { employerDetails } = useSelector((state: RootState) => state.employer);

  const [currentStep, setCurrentStep] = useState(1);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(true); // Added state for modal visibility

  const getEmployerData = useCallback(async () => {
    await dispatch(EmployerAction.getEmployerDetails());
    await dispatch(WebAction.getState());
    await dispatch(WebAction.getCompanyType());
  }, [dispatch]);

  useEffect(() => {
    getEmployerData();
  }, [getEmployerData]);

  useEffect(() => {
    if (!employerDetails?.profile_steps) return;

    const stepMap: { [key: string]: number } = {
      PROFILE: 1,
      KYC: 2,
      BANK: 3,
    };

    const nextStep = stepMap[employerDetails.profile_steps] || 1;

    if (firstRender.current) {
      firstRender.current = false;
      setCurrentStep(nextStep);
    }
  }, [employerDetails?.profile_steps]);

  const handleNextStep = () => {
    const messages: { [key: number]: string } = {
      1: "Congratulations! 🎊 You’ve successfully completed your first milestone 🎯 <br/> Now, take the next step by completing your KYC documents 📝.",
      2: "Congratulations! 🎊 You’ve successfully completed your second milestone 🎯 <br/> Now, take the next step by completing your Bank details 📝.",
      3: "Congratulations! 🎊 You’ve successfully completed all your milestones 🎯",
    };

    setMessage(messages[currentStep] || "");

    setTimeout(() => {
      setMessage("");

      setCurrentStep((prevStep) => {
        if (prevStep === 3) {
          return prevStep;
        }
        return prevStep + 1;
      });
    }, 2000);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1Form
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            editableData={employerDetails}
            getEmployerData={getEmployerData}
          />
        );
      case 2:
        return (
          <Step2Form
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            editableData={employerDetails}
            getEmployerData={getEmployerData}
          />
        );
      case 3:
        return (
          <Step3Form
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            editableData={employerDetails}
            getEmployerData={getEmployerData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {showModal && (
        <>
          {/* Overlay */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9998,
            }}
          />

          {/* Modal */}
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
              width: "90%",
              maxWidth: "700px",
              maxHeight: "90vh",
              overflowY: "auto",
              borderRadius: "10px",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              scrollbarColor: "#888 #f1f1f1",
              background: "white",
            }}
            className="custom-scrollbar"
          >
            <div
              style={{ background: "wheat" }}
              className="d-flex justify-content-between align-items-center p-2"
            >
              <h2 className="m-0">PLEASE COMPLETE THE FORM DETAILS.</h2>
              <p
                className={"m-0 label label-danger btn-sm"}
                style={{ cursor: "pointer" }}
                onClick={() => setShowModal(false)} // Close modal on click
              >
                Close
              </p>
            </div>
            {message && (
              <div
                className="p-2"
                style={{ background: "linear-gradient(45deg, #dddddd, #fff)" }}
              >
                <h5
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              </div>
            )}

            {renderStep()}
          </div>
        </>
      )}
    </>
  );
};

export default StepModal;
