import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import LeaveAction from "../../stores/action/leaveAction";
import moment from "moment";
import swal from "sweetalert";
import FilterSorting from "../../components/FilterSorting";
import Breadcrumb from "../../components/Breadcrumb";
import Pagination from "../../components/Pagination";
import TableLoader from "../../components/TableLoader";

function LeaveRequestList() {
  const [loading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const [type, setType] = useState<any>("PENDING");
  const dispatch = useDispatch<any>();

  const {
    leaveRequestList,
    pending,
    approved,
    rejected,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
  } = useSelector((state: RootState) => state.leave);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getLeaveRequest = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        LeaveAction.getLeaveRequestList({
          limit: limit,
          search: search,
          page: page,
          type: type,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page, type]);

  useEffect(() => {
    getLeaveRequest();
  }, [getLeaveRequest, search, limit, page, type]);

  const handleRemove = async (id: number) => {
    const status = await swal({
      title: "Leave Application",
      // text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        confirm: {
          text: "Approve",
          value: "APPROVED",
          visible: true,
          className: "approved-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
        rejected: {
          text: "Reject",
          value: "REJECTED",
          visible: true,
          className: "delete-button", // Custom class for styling
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: "cancel",
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
      },
    });

    if (status !== "cancel") {
      try {
        setLoading(true);
        await dispatch(LeaveAction.updateLeaveRequest({ id, status }));
        swal("Leave " + status.toLowerCase() + "!", {
          icon: "success",
        });
        getLeaveRequest();
      } catch (error) {
        swal("Error!", "There was an issue update the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <WebLayout pageName={"LeaveRequestList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Leave"} subTitle={"Leave Applications"} />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div
                  className="header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2>ALL LEAVE APPLICATIONS LIST</h2>
                  <div>
                    <button
                      className="btn btn-warning btn-sm btn-block"
                      type="button"
                      onClick={() => setType("PENDING")}
                    >
                      <i className="fa fa-users"></i> Pending
                      <span className="badge font-bold">{pending}</span>
                    </button>
                    <button
                      className="btn btn-success btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("APPROVED")}
                    >
                      <i className="fa fa-users"></i> Approved
                      <span className="badge font-bold">{approved}</span>
                    </button>

                    <button
                      className="btn btn-danger btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("REJECTED")}
                    >
                      <i className="fa fa-users"></i> Rejected
                      <span className="badge font-bold">{rejected}</span>
                    </button>

                    <button
                      className="btn btn-primary btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("")}
                    >
                      <i className="fa fa-users"></i> ALL
                      <span className="badge font-bold">
                        {leaveRequestList?.length}
                      </span>
                    </button>
                  </div>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Employee Id</th>
                          <th> Name</th>
                          <th> Date</th>
                          <th> Day</th>
                          <th> Leave Type</th>
                          <th> Reason</th>
                          <th> Applied On</th>
                          <th> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={9} />
                        ) : leaveRequestList?.length > 0 ? (
                          leaveRequestList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {dataList.employeeId
                                      ? dataList.employeeId
                                      : "N/A"}
                                  </td>
                                  <td>{dataList.name}</td>
                                  <td>
                                    From :{" "}
                                    {moment(dataList.start_date).format(
                                      "D-MMM-YYYY",
                                    )}
                                    <br />
                                    To :{" "}
                                    {moment(dataList.end_date).format(
                                      "D-MMM-YYYY",
                                    )}
                                  </td>
                                  <td>{dataList.days}</td>
                                  <td>{dataList.leave_type}</td>
                                  <td>
                                    {dataList.reason ? dataList.reason : "N/A"}
                                  </td>
                                  <td>
                                    {moment(dataList.created_at).format(
                                      "D-MMM-YYYY",
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => handleRemove(dataList.id)}
                                      disabled={loading || type !== "PENDING"}
                                    >
                                      {loading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      ) : (
                                        "Action"
                                      )}
                                    </button>
                                  </td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={9} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {leaveRequestList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default LeaveRequestList;
