import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import ProfileType, {
  GetEmployeeProfile,
  UpdateBankDetails,
  UpdateKYCDetails,
  UpdateProfile,
} from "../type/profileType";
import toast from "react-hot-toast";
import LogoutAction from "./LogoutAction";
import { getCookie } from "../../utils/CookieAction";

interface Action {
  type: string;
  payload?: any;
}

const ProfileAction = {
  getProfile:
    (data: GetEmployeeProfile): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.get(
          BASE_URL + "employee/get/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );
        if (result.data.status) {
          dispatch({
            type: ProfileType.SET_PROFILE_DATA,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateProfile:
    (data: UpdateProfile): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.post(
          BASE_URL + "employee/update",
          {
            employeeId: data.id,
            profile_img: data.profile_img,
            name: data.name,
            f_name: data.f_name,
            email: data.email,
            mobile: data.mobile,
            state: data.state,
            district: data.district,
            pincode: data.pincode,
            present_address: data.present_address,
            permanent_address: data.permanent_address,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: ProfileType.UPDATE_PROFILE_DATA,
          });
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateBankDetails:
    (data: UpdateBankDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.post(
          BASE_URL + "employee/update-bank-details",
          {
            employeeId: data.id,
            account_holder_name: data.account_holder_name,
            account_number: data.account_number,
            bank_name: data.bank_name,
            branch_name: data.branch_name,
            ifsc_code: data.ifsc_code,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: ProfileType.UPDATE_BANK_DETAILS,
          });
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateKYCDetails:
    (data: UpdateKYCDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }
      try {
        const result: any = await axios.post(
          BASE_URL + "employee/update-kyc-details",
          {
            employeeId: data.id,
            pan_img: data.pan_img,
            pan_no: data.pan_no,
            aadhar_img: data.aadhar_img,
            aadhar_no: data.aadhar_no,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );
        dispatch({
          type: ProfileType.UPDATE_KYC_DETAILS,
        });
        if (result.data.status) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default ProfileAction;
