import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";

import LogoutAction from "./LogoutAction";
import AttendanceType, {
  EmployeeAttendance,
  GetAttendanceType,
  ViewAttendance,
} from "../type/attendanceType";
import { getCookie } from "../../utils/CookieAction";

interface Action {
  type: string;
  payload?: any;
}

const AttendanceAction = {
  getAttendanceEmployees:
    (data: GetAttendanceType): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(
          BASE_URL + "attendance/" + data.date,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: AttendanceType.GET_ATTENDANCE_EMPLOYEES,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  submitAttendance:
    (payload: any): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "attendance/store",
          {
            attendances: payload,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: AttendanceType.SUBMIT_ATTENDANCE,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  viewAttendance:
    (data: ViewAttendance): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "attendance/view",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            fromDate: data.startDate,
            toDate: data.endDate,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: AttendanceType.VIEW_ATTENDANCE,
            payload: {
              data: result.data.data.data,
              currentPage: result.data.data.current_page,
              lastPage: result.data.data.last_page,
              totalData: result.data.data.total,
              from: result.data.data.from,
              to: result.data.data.to,
            },
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  employeeAttendanceSummary:
    (data: EmployeeAttendance): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "attendance/employee",
          {
            id: data.id,
            fromDate: data.startDate,
            toDate: data.endDate,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: AttendanceType.EMPLOYEE_ATTENDANCE,
            payload: {
              data: result.data.data,
            },
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default AttendanceAction;
