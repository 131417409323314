const SalaryType = {
  SALARY_GENERATE_LIST: "SALARY_GENERATE_LIST",
  SALARY_PAYMENT_SLIP: "SALARY_PAYMENT_SLIP",
  GET_SALARY_STRUCTURE: "GET_SALARY_STRUCTURE",
  GET_SALARY_PERCENTAGE: "GET_SALARY_PERCENTAGE",
  SUBMIT_SALARY_PERCENTAGE: "SUBMIT_SALARY_PERCENTAGE",
};

export interface SalaryGeneratedList {
  search: string;
  limit: number;
  page: number;
}

export interface SalaryPaymentSlipType {
  paymentSlip: any;
  employeeId: Array<string>;
  totalAmount: number;
  totalGstAmount: number;
  totalServiceFee: number;
  totalAmountReceivable: number;
}

export interface SalaryPercentage {
  basicPercentage: number;
  hraPercentage: number;
  otherAllowancePercentage: number;
}

export default SalaryType;
