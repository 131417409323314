import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import EmployerAction from "../../../stores/action/employerAction";
import { useFetchBankDetails } from "../../../hooks/useFetchBankDetails";

type FormData = {
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
};

function Step3Form({ editableData, getEmployerData, onNext, onPrevious }: any) {
  const dispatch = useDispatch<any>();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  const [btnLoading, setBtnLoading] = useState(false);
  const ifscCode = watch("ifscCode");

  const { loading: bankLoading } = useFetchBankDetails(
    ifscCode,
    setValue,
    setError,
  );

  useEffect(() => {
    setValue("accountHolderName", editableData?.account_holder_name);
    setValue("accountNumber", editableData?.account_number);
    setValue("bankName", editableData?.bank_name);
    setValue("branchName", editableData?.branch_name);
    setValue("ifscCode", editableData?.ifsc_code);
  }, [editableData, setValue]);

  const formHandleSubmit = async (data: any) => {
    setBtnLoading(true);
    try {
      await dispatch(
        EmployerAction.updateBankDetails(
          {
            accountHolderName: data.accountHolderName,
            accountNumber: data.accountNumber,
            bankName: data.bankName,
            branchName: data.branchName,
            ifscCode: data.ifscCode,
          },
          "stepModal",
        ),
      );
      setBtnLoading(false);
      reset();
      getEmployerData();
      onNext();
    } catch (error: any) {
      setBtnLoading(false);
      toast.error(error, {
        className: "custom-toast",
      });
    }
  };

  const handleIfsc = () => {
    if (!watch("bankName") || !watch("branchName")) {
      alert("You need to enter the IFSC code.");
    }
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card project_widget border-radius-per-0 m-0">
          <div className={"card-header"} style={{ backgroundColor: "#24245c" }}>
            <h2 className={"text-center m-0 text-white"}>
              BANK ACCOUNT DETAILS
            </h2>
          </div>
          <div className="card-body">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(formHandleSubmit)}
              encType="multipart/form-data"
            >
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Account Holder Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-placeholder-white"
                      placeholder="Account Holder Name"
                      {...register("accountHolderName", { required: true })}
                      required
                    />
                    {errors.accountHolderName && (
                      <span className="text-danger">
                        Account Holder Name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Account Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-placeholder-white"
                      placeholder="Account Number"
                      {...register("accountNumber", { required: true })}
                      required
                      onKeyDown={(e) => {
                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.accountNumber && (
                      <span className="text-danger">
                        Account Number is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      IFSC Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-placeholder-white"
                      placeholder="IFSC Code"
                      {...register("ifscCode", { required: true })}
                      required
                    />
                    {errors.ifscCode && (
                      <span className="text-danger">
                        {errors?.ifscCode.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Bank Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-placeholder-white"
                      placeholder="Bank Name"
                      {...register("bankName", { required: true })}
                      readOnly
                      onClick={handleIfsc}
                      value={bankLoading ? "Fetching..." : watch("bankName")}
                      required
                    />
                    {errors.bankName && (
                      <span className="text-danger">Bank Name is required</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Branch Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-placeholder-white"
                      placeholder="Branch Name"
                      {...register("branchName", { required: true })}
                      readOnly
                      onClick={handleIfsc}
                      value={bankLoading ? "Fetching..." : watch("branchName")}
                      required
                    />
                    {errors.branchName && (
                      <span className="text-danger">
                        Branch Name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-12 text-end">
                  <button
                    type={"button"}
                    className="btn btn-default btn-round border-radius-per-0"
                    onClick={() => {
                      onPrevious();
                    }}
                  >
                    &nbsp;Previous
                  </button>
                  &emsp;
                  <button
                    className="btn btn-dark btn-round"
                    disabled={btnLoading}
                  >
                    {btnLoading ? (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    ) : null}
                    &nbsp; Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step3Form;
