import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/Breadcrumb";
import SalaryAction from "../../stores/action/salaryAction";
import { RootState } from "../../stores";

function SalaryPercentage() {
  const dispatch = useDispatch<any>();
  const { salaryPercentage } = useSelector((state: RootState) => state.salary);

  const [basicSalary, setBasicSalary] = useState<string>("");
  const [hra, setHRA] = useState<string>("");
  const [otherAllowance, setOtherAllowance] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const getSalaryData = useCallback(async () => {
    await dispatch(SalaryAction.getSalaryPercentage());
  }, [dispatch]);

  useEffect(() => {
    getSalaryData();
  }, [getSalaryData]);

  useEffect(() => {
    if (salaryPercentage) {
      setBasicSalary(salaryPercentage?.basic_salary || "");
      setHRA(salaryPercentage?.hra || "");
      setOtherAllowance(salaryPercentage?.other_allowance || "");
    }
  }, [salaryPercentage]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const totalPercentage =
      Number(basicSalary) + Number(hra) + Number(otherAllowance);

    if (totalPercentage !== 100) {
      alert("Total percentage must be exactly 100%" + totalPercentage);
      setIsLoading(false);
      return;
    }

    const salaryData: any = {
      basicPercentage: basicSalary,
      hraPercentage: hra,
      otherAllowancePercentage: otherAllowance,
    };

    try {
      await dispatch(SalaryAction.submitSalaryPercentage(salaryData));
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <WebLayout pageName={"SalaryPercentage"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Salary"} subTitle={"Salary Percentage"} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="body">
                    <div className="row clearfix">
                      <div className="col-lg-12 col-md-12">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>
                                Basic Salary (in Percentage){" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Basic Salary (in Percentage)"
                                value={basicSalary}
                                onChange={(e) =>
                                  setBasicSalary(
                                    e.target.value.replace(/[^0-9.]/g, ""),
                                  )
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>HRA (in Percentage) </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="HRA (in Percentage)"
                                value={hra}
                                onChange={(e) =>
                                  setHRA(e.target.value.replace(/[^0-9.]/g, ""))
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Other Allowance (in Percentage) </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Other Allowance (in Percentage)"
                                value={otherAllowance}
                                onChange={(e) =>
                                  setOtherAllowance(
                                    e.target.value.replace(/[^0-9.]/g, ""),
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </WebLayout>
  );
}

export default SalaryPercentage;
