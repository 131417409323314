import React from "react";
import { Link } from "react-router-dom";

const EMASteps = ({ department, designation }: any) => {
  return (
    <div className={"row"}>
      <div className={"col-lg-12 col-md-12"} style={{ background: "#d1d1fd" }}>
        <h5>Essential Modules to Add Before Creating an EMA Account : -</h5>
        <ul className="numbered-list">
          <li style={{ fontSize: 16 }}>
            <strong>1) Department Management</strong>&nbsp;
            {department.length > 0 ? (
              <span style={{ fontSize: 20, color: "green" }}>✔</span>
            ) : (
              <>
                <span style={{ fontSize: 20, color: "red" }}>✘</span>
                &emsp;
                <Link to={"/department"}>
                  👉 <strong>Click Here to Add</strong>
                </Link>
              </>
            )}
          </li>
          <li style={{ fontSize: 16 }}>
            <strong>2) Designation Management</strong>&nbsp;
            {designation > 0 ? (
              <span style={{ fontSize: 20, color: "green" }}>✔</span>
            ) : (
              <>
                <span style={{ fontSize: 20, color: "red" }}>✘</span>
                &emsp;
                <Link to={"/designation"}>
                  👉 <strong>Click Here to Add</strong>
                </Link>
              </>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EMASteps;
