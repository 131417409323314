import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";
import LogoutAction from "./LogoutAction";
import SalaryType, {
  SalaryGeneratedList,
  SalaryPaymentSlipType,
  SalaryPercentage,
} from "../type/salaryType";
import { getCookie } from "../../utils/CookieAction";

interface Action {
  type: string;
  payload?: any;
}

const SalaryAction = {
  getSalaryGenerate:
    (data: SalaryGeneratedList): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "salary/generate",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: SalaryType.SALARY_GENERATE_LIST,
            payload: {
              data: result.data.data.data,
              currentPage: result.data.data.current_page,
              lastPage: result.data.data.last_page,
              totalData: result.data.data.total,
              from: result.data.data.from,
              to: result.data.data.to,
              date: result.data.data.generateDate,
            },
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  salaryPaymentSlip:
    (data: SalaryPaymentSlipType): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "salary/pay",
          {
            paymentSlip: data.paymentSlip,
            emp_no: data.employeeId,
            totalAmount: data.totalAmount,
            totalGstAmount: data.totalGstAmount,
            totalServiceFee: data.totalServiceFee,
            totalAmountReceivable: data.totalAmountReceivable,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: SalaryType.SALARY_PAYMENT_SLIP,
          });
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  getSalaryStructure:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(
          BASE_URL + "salary/structure/fetch",
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: SalaryType.GET_SALARY_STRUCTURE,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  getSalaryPercentage:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(
          BASE_URL + "salary/manage-percentage",
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: SalaryType.GET_SALARY_PERCENTAGE,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  submitSalaryPercentage:
    (data: SalaryPercentage): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "salary/percentage",
          {
            basic_salary_percentage: data.basicPercentage,
            hra_percentage: data.hraPercentage,
            other_allowance_percentage: data.otherAllowancePercentage,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: SalaryType.SUBMIT_SALARY_PERCENTAGE,
          });
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default SalaryAction;
