import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import EmployeeType, {
  CheckEmployeeType,
  createEmployeeStore,
  GetEmployee,
  RemoveEmployee,
} from "../type/employeeType";
import toast from "react-hot-toast";
import { getCookie } from "../../utils/CookieAction";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const EmployeeAction = {
  getEmployeeList:
    (data: GetEmployee): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "employee/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            type: data.type,
            employeeStatus: data.employeeStatus,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployeeType.GET_EMPLOYEE_LIST,
            payload: {
              data: result.data.data.employees.data,
              currentPage: result.data.data.employees.current_page,
              lastPage: result.data.data.employees.last_page,
              totalData: result.data.data.employees.total,
              from: result.data.data.employees.from,
              to: result.data.data.employees.to,
              totalApprovedKYC: result.data.data.totalApprovedKYC,
              totalPendingKYC: result.data.data.totalPendingKYC,
              totalSendForApprovalKYC: result.data.data.totalSendForApprovalKYC,
              totalRejectedKYC: result.data.data.totalRejectedKYC,
            },
          });
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  createEmployee:
    (
      data: createEmployeeStore,
    ): ThunkAction<Promise<any>, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return Promise.reject("Unauthorized: No token found.");
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "employee/create",
          {
            image: data.image,
            name: data.name,
            fatherName: data.fatherName,
            dateOfBirth: data.dateOfBirth,
            phone: data.phone,
            state: data.state,
            district: data.district,
            pinCode: data.pinCode,
            correspondenceAddress: data.correspondenceAddress,
            permanentAddress: data.permanentAddress,
            email: data.email,
            password: data.password,
            employeeType: data.employeeType,
            employeeId: data.employeeID,
            department: data.department,
            designation: data.designation,
            dateOfJoining: data.dateOfJoining,
            basicSalary: data.basicSalary,
            accountHolderName: data.accountHolderName,
            accountNumber: data.accountNumber,
            bankName: data.bankName,
            ifscCode: data.ifscCode,
            branch: data.branch,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({ type: EmployeeType.CREATE_EMPLOYEE });
          toast.success(result.data.msg);
          return Promise.resolve(result.data); // ✅ Return Success Response
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, { className: "custom-toast" });
          return Promise.reject(errorMsg); // ❌ Return Error Message
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, { className: "custom-toast" });

        return Promise.reject(errorMsg); // ❌ Return API Error
      }
    },

  removeEmployee:
    (data: RemoveEmployee): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "employee/remove",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: EmployeeType.REMOVE_EMPLOYEE,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  getEmployeeRecordList:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(BASE_URL + "employee/record", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        });

        if (result.data.status) {
          dispatch({
            type: EmployeeType.EMPLOYEE_RECORD_LIST,
            payload: result.data.data,
          });
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  checkEmploymentType:
    (data: CheckEmployeeType): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        return await axios.post(
          BASE_URL + "employee/checkEmploymentType",
          {
            state: data.state,
            employmentType: data.employeeType,
            basic_salary: data.basicSalary,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default EmployeeAction;
