import React, { useEffect, useCallback, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";
import { BASE_URL, today } from "../../utils/Constants";
import EmployeeAction from "../../stores/action/employeeAction";
import Breadcrumb from "../../components/Breadcrumb";
import SalaryAction from "../../stores/action/salaryAction";
import EMASteps from "../../components/EMASteps";
import DashboardAction from "../../stores/action/dashboardAction";
import AddDepartmentModal from "../../components/AddDepartmentModal";
import AddDesignationModal from "../../components/AddDesignationModal";

type FormData = {
  image: File | null;
  name: string;
  fatherName: string;
  dateOfBirth: string;
  phone: string;
  state: string;
  district: string;
  pinCode: string;
  correspondenceAddress: string;
  permanentAddress: string;
  email: string;
  password: string;
  employeeType: string;
  employeeID: string;
  department: string;
  designation: string;
  dateOfJoining: string;
  basicSalary: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: any;
  ifscCode: string;
  branch: any;
};

function CreateEmployee() {
  const dispatch = useDispatch<any>();
  const {
    stateList,
    districtList,
    employeeDepartmentList,
    employeeDesignationList,
  } = useSelector((state: RootState) => state.web);

  const { designationCount } = useSelector(
    (state: RootState) => state.dashboard,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [departmentModal, setDepartmentModal] = useState<boolean>(false);
  const [designationModal, setDesignationModal] = useState<boolean>(false);

  const [formData, setFormData] = useState<FormData>({
    image: null,
    name: "",
    fatherName: "",
    dateOfBirth: "",
    phone: "",
    state: "",
    district: "",
    pinCode: "",
    correspondenceAddress: "",
    permanentAddress: "",
    email: "",
    password: "",
    employeeID: "",
    department: "",
    designation: "",
    dateOfJoining: "",
    employeeType: "",
    basicSalary: "",
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    branch: "",
  });

  useEffect(() => {
    if (formData.ifscCode?.trim().length === 11) {
      fetchBankDetails(formData.ifscCode);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        bankName: "",
        branch: "",
      }));
    }
  }, [formData.ifscCode]);

  const fetchBankDetails = async (ifsc: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}match-ifsc-code/${ifsc}`);
      const { data } = await response.json();

      if (data && data.bankName && data.branch) {
        setFormData((prevData) => ({
          ...prevData,
          bankName: data.bankName,
          branch: data.branch,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          ifscCode: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ifscCode: "Invalid IFSC Code",
        }));
        setFormData((prevData) => ({
          ...prevData,
          bankName: "",
          branch: "",
        }));
      }
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ifscCode: "Invalid IFSC Code",
      }));
      setFormData((prevData) => ({
        ...prevData,
        bankName: "",
        branch: "",
      }));
    } finally {
      setLoading(false);
    }
  };

  // Form state

  const [response, setResponse] = useState("");
  // Error state
  const [errors, setErrors] = useState<{ [key in keyof FormData]?: string }>(
    {},
  );

  const [btnLoading, setBtnLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const getStates = useCallback(async () => {
    await dispatch(WebAction.getState());
  }, [dispatch]);

  const getDistrict = useCallback(
    async (state_id: any) => {
      try {
        await dispatch(WebAction.getDistrict({ state_id }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch],
  );

  // Get Designation From Department Id

  const getDesignation = useCallback(
    async (departmentId: any) => {
      try {
        await dispatch(WebAction.getDesignation(departmentId));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch],
  );

  const getDepartment = useCallback(async () => {
    await dispatch(WebAction.getDepartment());
  }, [dispatch]);

  useEffect(() => {
    getStates();
    getDepartment();
    dispatch(DashboardAction.getDesignationCount());
    dispatch(SalaryAction.getSalaryStructure());
  }, [dispatch, getDepartment, getStates]);

  const checkEmploymentType = useCallback(async () => {
    if (
      formData.basicSalary.length > 3 &&
      formData.state &&
      formData.employeeType
    ) {
      try {
        const result: any = await dispatch(
          EmployeeAction.checkEmploymentType({
            state: formData.state,
            employeeType: formData.employeeType,
            basicSalary: formData.basicSalary,
          }),
        );
        setResponse(result.data.msg);
      } catch (e) {
        console.error("Error checking employment type:", e);
      }
    }
  }, [dispatch, formData.basicSalary, formData.employeeType, formData.state]);

  useEffect(() => {
    checkEmploymentType();
  }, [checkEmploymentType]);

  const handleDepartmentChange = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const departmentId = e.target.value;
    setFormData((prev) => ({
      ...prev,
      department: departmentId,
      designation: "",
    }));
    setErrors(
      (prevErrors) =>
        ({
          ...prevErrors,
          department: "",
        }) as Record<string, string>,
    );
    await getDesignation(departmentId);
  };

  const handleStateChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const stateId = e.target.value;
    setFormData((prev) => ({ ...prev, state: stateId, district: "" }));
    setErrors(
      (prevErrors) =>
        ({
          ...prevErrors,
          state: "",
        }) as Record<string, string>,
    );
    await getDistrict(stateId);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const fileInput = e.target as HTMLInputElement;
      const file = fileInput.files?.[0] || null;

      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const getStepFields = (step: number): Array<keyof FormData> => {
    switch (step) {
      case 1:
        return [
          "image",
          "name",
          "fatherName",
          "dateOfBirth",
          "phone",
          "state",
          "district",
          "pinCode",
          "correspondenceAddress",
          "permanentAddress",
          "employeeID",
          "department",
          "designation",
          "dateOfJoining",
          "employeeType",
          "basicSalary",
        ];
      case 2:
        return [
          "email",
          "password",
          "accountHolderName",
          "accountNumber",
          "bankName",
          "ifscCode",
          "branch",
        ];
      default:
        return [];
    }
  };

  const validateStep = (step: number): boolean => {
    const stepFields = getStepFields(step);
    const newErrors: { [key in keyof FormData]?: string } = {};

    stepFields.forEach((field) => {
      const value: any = formData[field];
      switch (field) {
        case "image":
          if (!value) {
            newErrors.image = "Image is required";
          } else {
            const acceptedFormats = ["image/jpeg", "image/png", "image/gif"];
            if (!acceptedFormats.includes((value as File).type)) {
              newErrors.image = "Only JPEG, PNG, or GIF files are allowed";
            }
            if ((value as File).size > 2 * 1024 * 1024) {
              newErrors.image = "Image size should not exceed 2MB";
            }
          }
          break;
        case "name":
          if (!value.trim()) newErrors.name = "Name is required";
          break;
        case "fatherName":
          if (!value.trim()) newErrors.fatherName = "Father's Name is required";
          break;
        case "dateOfBirth":
          if (!value) {
            newErrors.dateOfBirth = "Date of Birth is required";
          } else if (value > today) {
            newErrors.dateOfBirth = "Date of Birth cannot be in the future";
          }
          break;
        case "phone":
          if (!value) {
            newErrors.phone = "Phone is required";
          } else if (!/^[0-9]{10}$/.test(value)) {
            newErrors.phone = "Phone must be 10 digits";
          }
          break;
        case "state":
          if (!value) newErrors.state = "State is required";
          break;
        case "pinCode":
          if (!value) {
            newErrors.pinCode = "Pincode is required";
          } else if (!/^[0-9]{6}$/.test(value)) {
            newErrors.pinCode = "Pincode must be 6 digits";
          }
          break;
        case "correspondenceAddress":
          if (!value.trim())
            newErrors.correspondenceAddress =
              "Correspondence Address is required";
          break;
        case "permanentAddress":
          if (!value.trim())
            newErrors.permanentAddress = "Permanent Address is required";
          break;
        case "email":
          if (!value) {
            newErrors.email = "Email is required";
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            newErrors.email = "Invalid email address";
          }
          break;
        case "password":
          if (!value) {
            newErrors.password = "Password is required";
          } else if ((value as string).length < 6) {
            newErrors.password = "Password must be at least 6 characters";
          }
          break;
        case "employeeID":
          if (!value.trim()) {
            newErrors.employeeID = "Employee ID is required";
          }
          break;
        case "department":
          if (!value) {
            newErrors.department = "Department is required";
          }
          break;
        case "designation":
          if (!value) {
            newErrors.designation = "Designation is required";
          }
          break;
        case "dateOfJoining":
          if (!value) {
            newErrors.dateOfJoining = "Date of Joining is required";
          } else if (value > today) {
            newErrors.dateOfJoining = "Date of Joining cannot be in the future";
          }
          break;
        case "employeeType":
          if (!value) {
            newErrors.employeeType = "Employee Type is required";
          }
          break;
        case "basicSalary":
          if (!value) {
            newErrors.basicSalary = "Joining Salary is required";
          } else if (Number(value) < 0) {
            newErrors.basicSalary = "Joining Salary must be a positive number";
          }
          break;
        case "accountHolderName":
          if (!value.trim()) {
            newErrors.accountHolderName = "Account Holder Name is required";
          }
          break;
        case "accountNumber":
          if (!value) {
            newErrors.accountNumber = "Account Number is required";
          } else if (!/^[0-9]{9,18}$/.test(value)) {
            newErrors.accountNumber =
              "Account Number must be between 9 to 18 digits";
          }
          break;
        case "bankName":
          if (!value.trim()) {
            newErrors.bankName = "Bank Name is required";
          }
          break;
        case "ifscCode":
          if (!value) {
            newErrors.ifscCode = "IFSC Code is required";
          }
          break;
        case "branch":
          if (!value.trim()) {
            newErrors.branch = "Branch is required";
          }
          break;
        default:
          break;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setBtnLoading(true);

    const isValid = validateStep(currentStep);

    if (!isValid) {
      setBtnLoading(false);
      return;
    }

    // If on the last step, submit the form
    if (currentStep === 2) {
      // Perform final validation for all steps
      let allValid = true;
      for (let step = 1; step <= 2; step++) {
        if (!validateStep(step)) {
          allValid = false;
          setCurrentStep(step);
          break;
        }
      }

      if (!allValid) {
        setBtnLoading(false);
        return;
      }

      const updatedFormData = {
        ...formData,
      };

      try {
        const response = await dispatch(
          EmployeeAction.createEmployee(updatedFormData),
        );

        if (response.status) {
          resetForm();
          setCurrentStep(1);
          setErrors({});
        }
      } catch (error) {
        console.error("Submission Error:", error);
      } finally {
        setBtnLoading(false);
      }
    } else {
      // Move to the next step
      const nextStepNumber = currentStep + 1;
      setCurrentStep(nextStepNumber);
      setBtnLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      image: null,
      name: "",
      fatherName: "",
      dateOfBirth: "",
      phone: "",
      state: "",
      district: "",
      pinCode: "",
      correspondenceAddress: "",
      permanentAddress: "",
      email: "",
      password: "",
      employeeType: "",
      employeeID: "",
      department: "",
      designation: "",
      dateOfJoining: "",
      basicSalary: "",
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      ifscCode: "",
      branch: "",
    });
  };

  const nextStep = () => {
    const isValid = validateStep(currentStep);
    if (isValid) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const isNextButtonDisabled =
    (currentStep < 2 && employeeDepartmentList.length === 0) ||
    designationCount === 0;

  const isButtonDisabled =
    btnLoading || (response !== "disabled" && currentStep === 1);

  return (
    <WebLayout pageName="CreateEmployee">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"EMA Account"}
                subTitle={"Create EMA Account (Employee)"}
              />
            </div>
          </div>

          {isNextButtonDisabled && (
            <EMASteps
              department={employeeDepartmentList}
              designation={designationCount}
            />
          )}

          <AddDepartmentModal
            departmentModal={departmentModal}
            setDepartmentModal={setDepartmentModal}
            getDepartmentList={getDepartment}
          />

          <AddDesignationModal
            designationModal={designationModal}
            setDesignationModal={setDesignationModal}
            getDesignation={getDesignation}
            deptId={formData.department}
          />

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="profile-tab-box">
                  <ul className="nav">
                    {[1, 2].map((step) => (
                      <li
                        key={step}
                        className={`nav-item tab-all ${
                          currentStep >= step ? "active-step" : ""
                        }`}
                        style={{ width: `${100 / 2}%`, textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          disabled={currentStep < step}
                          onClick={() => setCurrentStep(step)}
                        >
                          {`${step}. ${
                            step === 1
                              ? "PERSONAL & EMPLOYEE DETAILS"
                              : "ACCOUNT LOGIN & BANK ACCOUNT DETAILS"
                          }`}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="card project_widget">
                <div className="body pt-0">
                  <form onSubmit={onSubmit} encType="multipart/form-data">
                    {currentStep === 1 ? (
                      <>
                        <h5 className={"text-black"}>PERSONAL DETAILS</h5>
                        <div className="row clearfix">
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Image <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"image"}
                                type="file"
                                className="form-control"
                                accept="image/*"
                                onChange={handleChange}
                              />
                              {errors.image && (
                                <span className="text-danger">
                                  {errors.image}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"name"}
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Father's Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name={"fatherName"}
                                className="form-control"
                                placeholder="Father's Name"
                                value={formData.fatherName}
                                onChange={handleChange}
                              />
                              {errors.fatherName && (
                                <span className="text-danger">
                                  {errors.fatherName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Date Of Birth{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"dateOfBirth"}
                                type="date"
                                className="form-control"
                                value={formData.dateOfBirth}
                                onChange={handleChange}
                                max={`${today}`}
                              />
                              {errors.dateOfBirth && (
                                <span className="text-danger">
                                  {errors.dateOfBirth}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name={"phone"}
                                className="form-control"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleChange}
                                maxLength={10}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "Tab",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors.phone && (
                                <span className="text-danger">
                                  {errors.phone}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                State <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                value={formData.state || ""}
                                onChange={handleStateChange}
                              >
                                <option value="" disabled>
                                  Select State
                                </option>
                                {stateList?.map((val: any, index: number) => (
                                  <option key={index} value={val.id}>
                                    {val.name}
                                  </option>
                                ))}
                              </select>
                              {errors.state && (
                                <span className="text-danger">
                                  {errors.state}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>District</label>
                              <select
                                name={"district"}
                                className="form-control"
                                value={formData.district || ""}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Select District
                                </option>
                                {districtList?.map(
                                  (val: any, index: number) => (
                                    <option key={index} value={val.id}>
                                      {val.name}
                                    </option>
                                  ),
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Pincode <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"pinCode"}
                                type="text"
                                className="form-control"
                                placeholder="Pincode"
                                value={formData.pinCode}
                                onChange={handleChange}
                                maxLength={6}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "Tab",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors.pinCode && (
                                <span className="text-danger">
                                  {errors.pinCode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Correspondence Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                name={"correspondenceAddress"}
                                rows={4}
                                className="form-control no-resize"
                                placeholder="Correspondence Address"
                                value={formData.correspondenceAddress}
                                onChange={handleChange}
                              />
                              {errors.correspondenceAddress && (
                                <span className="text-danger">
                                  Correspondence Address is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Permanent Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                name={"permanentAddress"}
                                rows={4}
                                className="form-control no-resize"
                                placeholder="Permanent Address"
                                value={formData.permanentAddress}
                                onChange={handleChange}
                              />
                              {errors.permanentAddress && (
                                <span className="text-danger">
                                  Permanent Address is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <h5 className={"text-black mt-0"}>EMPLOYEE DETAILS</h5>
                        <div className={"row clearfix"}>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Employee ID{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"employeeID"}
                                type="text"
                                className="form-control"
                                placeholder="Employee ID"
                                value={formData.employeeID}
                                onChange={handleChange}
                              />
                              {errors.employeeID && (
                                <span className="text-danger">
                                  Employee Id is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <div className={"d-flex justify-content-between"}>
                                <label>
                                  Department
                                  <span className="text-danger">*</span>
                                </label>
                                <label
                                  className={"label label-danger"}
                                  onClick={() => setDepartmentModal(true)}
                                >
                                  Add Department
                                </label>
                              </div>
                              <select
                                name={"department"}
                                className="form-control"
                                value={formData.department}
                                onChange={handleDepartmentChange}
                              >
                                <option value="" disabled>
                                  Select Department
                                </option>
                                {employeeDepartmentList?.map(
                                  (val: any, index: number) => (
                                    <option key={index} value={val.id}>
                                      {val.name}
                                    </option>
                                  ),
                                )}
                              </select>
                              {errors.department && (
                                <span className="text-danger">
                                  Department is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <div className={"d-flex justify-content-between"}>
                                <label>
                                  Designation
                                  <span className="text-danger">*</span>
                                </label>
                                <label
                                  className={"label label-danger"}
                                  onClick={() => setDesignationModal(true)}
                                >
                                  Add Designation
                                </label>
                              </div>

                              <select
                                name={"designation"}
                                className="form-control"
                                value={formData.designation || ""}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Select Designation
                                </option>
                                {employeeDesignationList?.map(
                                  (val: any, index: number) => (
                                    <option key={index} value={val.id}>
                                      {val.name}
                                    </option>
                                  ),
                                )}
                              </select>
                              {errors.designation && (
                                <span className="text-danger">
                                  Designation is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Date Of Joining{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"dateOfJoining"}
                                type="date"
                                className="form-control"
                                value={formData.dateOfJoining}
                                onChange={handleChange}
                                max={`${today}`}
                              />
                              {errors.dateOfJoining && (
                                <span className="text-danger">
                                  Date of Joining is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Employment Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name={"employeeType"}
                                className="form-control"
                                value={formData.employeeType}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Select Employment Type
                                </option>
                                {["SKILLED", "UNSKILLED", "SEMISKILLED"]?.map(
                                  (val: any, index: number) => (
                                    <option key={index} value={val}>
                                      {val}
                                    </option>
                                  ),
                                )}
                              </select>
                              {errors.employeeType && (
                                <span className="text-danger">
                                  Employee Type is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Gross Salary{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"basicSalary"}
                                type="text"
                                className="form-control"
                                placeholder="Gross Salary"
                                value={formData.basicSalary}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "Tab",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors.basicSalary && (
                                <span className="text-danger">
                                  Basic Salary is required
                                </span>
                              )}
                              {response !== "disabled" && (
                                <div className={"text-danger text-center mb-3"}>
                                  {response}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h5 className={"text-black"}>ACCOUNT LOGIN</h5>
                        <div className="row clearfix">
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Email <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"email"}
                                type="email"
                                className="form-control"
                                placeholder="E-mail"
                                value={formData.email}
                                onChange={handleChange}
                              />
                              {errors.email && (
                                <span className="text-danger">
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div
                              className="form-group"
                              style={{ position: "relative" }}
                            >
                              <label>
                                Password <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"password"}
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                              />
                              <span
                                onClick={togglePasswordVisibility}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "40%",
                                  cursor: "pointer",
                                  color: "gray",
                                }}
                              >
                                {showPassword ? (
                                  <i className="fa fa-eye-slash"></i>
                                ) : (
                                  <i className="fa fa-eye"></i>
                                )}
                              </span>
                              {errors.password && (
                                <span className="text-danger">
                                  {errors.password}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <h5 className={"text-black"}>BANK DETAILS</h5>
                        <div className="row clearfix">
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Account Holder Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"accountHolderName"}
                                type="text"
                                className="form-control"
                                placeholder="Account Holder Name"
                                value={formData.accountHolderName}
                                onChange={handleChange}
                              />
                              {errors.accountHolderName && (
                                <span className="text-danger">
                                  Account Holder Name is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Account Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"accountNumber"}
                                type="text"
                                className="form-control"
                                placeholder="Account Number"
                                value={formData.accountNumber}
                                onChange={handleChange}
                                maxLength={18}
                                onKeyDown={(e) => {
                                  if (
                                    !/[0-9]/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "ArrowLeft",
                                      "Tab",
                                      "ArrowRight",
                                      "Delete",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errors.accountNumber && (
                                <span className="text-danger">
                                  Account Number is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                IFSC Code <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"ifscCode"}
                                type="text"
                                className="form-control"
                                placeholder="IFSC Code"
                                value={formData.ifscCode}
                                onChange={handleChange}
                                maxLength={11}
                                onKeyDown={(e) => {
                                  const allowed = /[A-Z0-9]/.test(e.key);
                                  const keyButtons = [
                                    "Backspace",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                  ].includes(e.key);
                                  if (!allowed && keyButtons) {
                                    e.preventDefault();
                                  }
                                }}
                                style={{ textTransform: "uppercase" }}
                              />
                              {errors.ifscCode && (
                                <span className="text-danger">
                                  {errors.ifscCode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Bank Name <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"bankName"}
                                type="text"
                                className="form-control"
                                placeholder="Bank Name"
                                value={
                                  loading ? "Fetching..." : formData.bankName
                                }
                                onChange={handleChange}
                                onClick={() =>
                                  formData.bankName
                                    ? ""
                                    : alert(`Enter IFSC Code`)
                                }
                                readOnly
                              />
                              {errors.bankName && (
                                <span className="text-danger">
                                  Bank Name is required
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-12">
                            <div className="form-group">
                              <label>
                                Branch <span className="text-danger">*</span>
                              </label>
                              <input
                                name={"branch"}
                                type="text"
                                className="form-control"
                                placeholder="Branch"
                                value={
                                  loading ? "Fetching..." : formData.branch
                                }
                                onChange={handleChange}
                                onClick={() =>
                                  formData.branch
                                    ? ""
                                    : alert(`Enter IFSC Code`)
                                }
                                readOnly
                              />
                              {errors.branch && (
                                <span className="text-danger">
                                  Branch is required
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-12 d-flex justify-content-between mt-3">
                      {currentStep > 1 && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={prevStep}
                          disabled={btnLoading}
                        >
                          Previous
                        </button>
                      )}
                      {currentStep < 2 && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={nextStep}
                          disabled={isNextButtonDisabled || isButtonDisabled}
                        >
                          Next
                        </button>
                      )}
                      {currentStep === 2 && (
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={btnLoading}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default CreateEmployee;
