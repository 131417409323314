import { Link } from "react-router-dom";
import ImageComponent from "../ImageComponent";
import React from "react";

function ViewKYCDetails({ editableData }: any) {
  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="header">
            <h2>KYC DOCUMENT</h2>
          </div>
          {editableData?.companyTypeName === "Proprietorship Firm" ? (
            <div className="body">
              <div className="row">
                <div className={"col-12"}>
                  <table className={"table table-bordered"}>
                    {editableData.pancard_no ? (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>{editableData.pancard_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.pancard_img ? (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>
                          <Link to={editableData.pancard_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.pancard_img}
                              width={100}
                              height={100}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_no ? (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>{editableData.gst_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_img ? (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>
                          <Link to={editableData.gst_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.gst_img}
                              width={100}
                              height={100}
                              altTitle={"GST Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.msme_no ? (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>{editableData.msme_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.msme_img ? (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>
                          <Link to={editableData.msme_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.msme_img}
                              width={100}
                              height={100}
                              altTitle={"MSME Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          ) : editableData?.companyTypeName === "Partnership Firm" ? (
            <div className="body">
              <div className="row">
                <div className={"col-12"}>
                  <table className={"table table-bordered"}>
                    {editableData.pancard_no ? (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>{editableData.pancard_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.pancard_img ? (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>
                          <Link to={editableData.pancard_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.pancard_img}
                              width={100}
                              height={100}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_no ? (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>{editableData.gst_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_img ? (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>
                          <Link to={editableData.gst_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.gst_img}
                              width={100}
                              height={100}
                              altTitle={"GST Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.partnership_no ? (
                      <tr>
                        <th>PARTNERSHIP NUMBER</th>
                        <td>{editableData.partnership_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PARTNERSHIP NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.partnership_img ? (
                      <tr>
                        <th>PARTNERSHIP IMAGE</th>
                        <td>
                          <Link
                            to={editableData.partnership_img}
                            target={"_blank"}
                          >
                            <ImageComponent
                              sourceImage={editableData.partnership_img}
                              width={100}
                              height={100}
                              altTitle={"Partnership Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PARTNERSHIP IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.msme_no ? (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>{editableData.msme_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.msme_img ? (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>
                          <Link to={editableData.msme_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.msme_img}
                              width={100}
                              height={100}
                              altTitle={"MSME Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          ) : editableData?.companyTypeName === "LLP" ? (
            <div className="body">
              <div className="row">
                <div className={"col-12"}>
                  <table className={"table table-bordered"}>
                    {editableData.pancard_no ? (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>{editableData.pancard_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.pancard_img ? (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>
                          <Link to={editableData.pancard_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.pancard_img}
                              width={100}
                              height={100}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_no ? (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>{editableData.gst_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_img ? (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>
                          <Link to={editableData.gst_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.gst_img}
                              width={100}
                              height={100}
                              altTitle={"GST Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.rc_no ? (
                      <tr>
                        <th>RC NUMBER</th>
                        <td>{editableData.rc_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>RC NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.rc_img ? (
                      <tr>
                        <th>RC IMAGE</th>
                        <td>
                          <Link to={editableData.rc_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.rc_img}
                              width={100}
                              height={100}
                              altTitle={"RC Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>RC IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.partnership_no ? (
                      <tr>
                        <th>PARTNERSHIP NUMBER</th>
                        <td>{editableData.partnership_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PARTNERSHIP NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.partnership_img ? (
                      <tr>
                        <th>PARTNERSHIP IMAGE</th>
                        <td>
                          <Link
                            to={editableData.partnership_img}
                            target={"_blank"}
                          >
                            <ImageComponent
                              sourceImage={editableData.partnership_img}
                              width={100}
                              height={100}
                              altTitle={"Partnership Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PARTNERSHIP IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.msme_no ? (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>{editableData.msme_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.msme_img ? (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>
                          <Link to={editableData.msme_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.msme_img}
                              width={100}
                              height={100}
                              altTitle={"MSME Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          ) : editableData?.companyTypeName === "Company" ? (
            <div className="body">
              <div className="row">
                <div className={"col-12"}>
                  <table className={"table table-bordered"}>
                    {editableData.pancard_no ? (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>{editableData.pancard_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.pancard_img ? (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>
                          <Link to={editableData.pancard_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.pancard_img}
                              width={100}
                              height={100}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_no ? (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>{editableData.gst_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_img ? (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>
                          <Link to={editableData.gst_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.gst_img}
                              width={100}
                              height={100}
                              altTitle={"GST Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.moa_no ? (
                      <tr>
                        <th>MOA NUMBER</th>
                        <td>{editableData.moa_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MOA NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.moa_img ? (
                      <tr>
                        <th>MOA IMAGE</th>
                        <td>
                          <Link to={editableData.moa_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.moa_img}
                              width={100}
                              height={100}
                              altTitle={"MOA Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MOA IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.aoa_no ? (
                      <tr>
                        <th>AOA NUMBER</th>
                        <td>{editableData.aoa_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>AOA NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.aoa_img ? (
                      <tr>
                        <th>AOA IMAGE</th>
                        <td>
                          <Link to={editableData.aoa_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.aoa_img}
                              width={100}
                              height={100}
                              altTitle={"AOA Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>AOA IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.coi_no ? (
                      <tr>
                        <th>COI NUMBER</th>
                        <td>{editableData.coi_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>COI NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.coi_img ? (
                      <tr>
                        <th>COI IMAGE</th>
                        <td>
                          <Link to={editableData.coi_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.coi_img}
                              width={100}
                              height={100}
                              altTitle={"COI Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>COI IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.msme_no ? (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>{editableData.msme_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.msme_img ? (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>
                          <Link to={editableData.msme_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.msme_img}
                              width={100}
                              height={100}
                              altTitle={"MSME Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          ) : editableData?.companyTypeName === "Any other entity" ? (
            <div className="body">
              <div className="row">
                <div className={"col-12"}>
                  <table className={"table table-bordered"}>
                    {editableData.pancard_no ? (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>{editableData.pancard_no}</td>
                      </tr>
                    ) : null}
                    {editableData.pancard_img ? (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>
                          <Link to={editableData.pancard_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.pancard_img}
                              width={100}
                              height={100}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : null}
                    {editableData.gst_no ? (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>{editableData.gst_no}</td>
                      </tr>
                    ) : null}
                    {editableData.gst_img ? (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>
                          <Link to={editableData.gst_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.gst_img}
                              width={100}
                              height={100}
                              altTitle={"GST Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : null}

                    {editableData.coi_no ? (
                      <tr>
                        <th>COI NUMBER</th>
                        <td>{editableData.coi_no}</td>
                      </tr>
                    ) : null}
                    {editableData.coi_img ? (
                      <tr>
                        <th>COI IMAGE</th>
                        <td>
                          <Link to={editableData.coi_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.coi_img}
                              width={100}
                              height={100}
                              altTitle={"COI Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : null}

                    {editableData.msme_no ? (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>{editableData.msme_no}</td>
                      </tr>
                    ) : null}
                    {editableData.msme_img ? (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>
                          <Link to={editableData.msme_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.msme_img}
                              width={100}
                              height={100}
                              altTitle={"MSME Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          ) : editableData?.companyTypeName === "Trust" ? (
            <div className="body">
              <div className="row">
                <div className={"col-12"}>
                  <table className={"table table-bordered"}>
                    {editableData.pancard_no ? (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>{editableData.pancard_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.pancard_img ? (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>
                          <Link to={editableData.pancard_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.pancard_img}
                              width={100}
                              height={100}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_no ? (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>{editableData.gst_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_img ? (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>
                          <Link to={editableData.gst_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.gst_img}
                              width={100}
                              height={100}
                              altTitle={"GST Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.trust_no ? (
                      <tr>
                        <th>TRUST NUMBER</th>
                        <td>{editableData.trust_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>TRUST NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.trust_img ? (
                      <tr>
                        <th>TRUST IMAGE</th>
                        <td>
                          <Link to={editableData.trust_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.trust_img}
                              width={100}
                              height={100}
                              altTitle={"Trust Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>TRUST IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.msme_no ? (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>{editableData.msme_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.msme_img ? (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>
                          <Link to={editableData.msme_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.msme_img}
                              width={100}
                              height={100}
                              altTitle={"MSME Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          ) : editableData?.companyTypeName === "Society" ? (
            <div className="body">
              <div className="row">
                <div className={"col-12"}>
                  <table className={"table table-bordered"}>
                    {editableData.pancard_no ? (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>{editableData.pancard_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.pancard_img ? (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>
                          <Link to={editableData.pancard_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.pancard_img}
                              width={100}
                              height={100}
                              altTitle={"PAN Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>PAN IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.rc_no ? (
                      <tr>
                        <th>RC NUMBER</th>
                        <td>{editableData.rc_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>RC NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.rc_img ? (
                      <tr>
                        <th>RC IMAGE</th>
                        <td>
                          <Link to={editableData.rc_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.rc_img}
                              width={100}
                              height={100}
                              altTitle={"RC Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>RC IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.moa_no ? (
                      <tr>
                        <th>MOA NUMBER</th>
                        <td>{editableData.moa_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MOA NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.moa_img ? (
                      <tr>
                        <th>MOA IMAGE</th>
                        <td>
                          <Link to={editableData.moa_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.moa_img}
                              width={100}
                              height={100}
                              altTitle={"MOA Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MOA IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_no ? (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>{editableData.gst_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.gst_img ? (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>
                          <Link to={editableData.gst_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.gst_img}
                              width={100}
                              height={100}
                              altTitle={"GST Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>GST IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}

                    {editableData.msme_no ? (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>{editableData.msme_no}</td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME NUMBER</th>
                        <td>N/A</td>
                      </tr>
                    )}
                    {editableData.msme_img ? (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>
                          <Link to={editableData.msme_img} target={"_blank"}>
                            <ImageComponent
                              sourceImage={editableData.msme_img}
                              width={100}
                              height={100}
                              altTitle={"MSME Image"}
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>MSME IMAGE</th>
                        <td>N/A</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ViewKYCDetails;
