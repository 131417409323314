const EmployeeType = {
  CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
  GET_EMPLOYEE_LIST: "GET_EMPLOYEE_LIST",
  REMOVE_EMPLOYEE: "REMOVE_EMPLOYEE",
  EMPLOYEE_RECORD_LIST: "EMPLOYEE_RECORD_LIST",
  CHECK_EMPLOYMENT_TYPE: "CHECK_EMPLOYMENT_TYPE",
};

export interface createEmployeeStore {
  image: any;
  name: any;
  fatherName: any;
  dateOfBirth: any;
  phone: any;
  state: any;
  district: any;
  pinCode: any;
  correspondenceAddress: any;
  permanentAddress: any;
  email: any;
  password: any;
  employeeType: any;
  employeeID: any;
  department: any;
  designation: any;
  dateOfJoining: any;
  basicSalary: any;
  accountHolderName: any;
  accountNumber: any;
  bankName: any;
  ifscCode: any;
  branch: any;
}

export interface GetEmployee {
  search: string;
  limit: number;
  page: number;
  type: string;
  employeeStatus: string;
}

export interface RemoveEmployee {
  id: Number;
}

export interface CheckEmployeeType {
  state: string;
  employeeType: string;
  basicSalary: string;
}

export default EmployeeType;
