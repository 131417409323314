import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";
import LogoutAction from "./LogoutAction";
import HolidayType, {
  CreateHoliday,
  GetHoliday,
  RemoveHoliday,
  UpdateHoliday,
} from "../type/holidayType";
import { getCookie } from "../../utils/CookieAction";

interface Action {
  type: string;
  payload?: any;
}

const HolidayAction = {
  getHolidayList:
    (data: GetHoliday): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "holiday/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            month: data.month,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: HolidayType.GET_HOLIDAY_LIST,
            payload: {
              data: result.data.data.data,
              currentPage: result.data.data.current_page,
              lastPage: result.data.data.last_page,
              totalData: result.data.data.total,
              from: result.data.data.from,
              to: result.data.data.to,
            },
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  createHoliday:
    (data: CreateHoliday): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "holiday/store",
          {
            name: data.name,
            date: data.date,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: HolidayType.CREATE_HOLIDAY,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateHoliday:
    (data: UpdateHoliday): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "holiday/update",
          {
            id: data.id,
            name: data.name,
            date: data.date,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: HolidayType.UPDATE_HOLIDAY,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  removeHoliday:
    (data: RemoveHoliday): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "holiday/remove",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: HolidayType.REMOVE_HOLIDAY,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  markAllSunday:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "holiday/mark-all-sunday",
          {},
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: HolidayType.MARK_SUNDAY,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  checkSunday:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(
          BASE_URL + "holiday/check-sundays",
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: HolidayType.CHECK_SUNDAY,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default HolidayAction;
