import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import AuthType, { LogIn } from "../type/authType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const AuthAction = {
  logIn:
    (data: LogIn): ThunkAction<Promise<any>, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const response: any = await axios.post(BASE_URL + "login", {
          email: data.email,
          password: data.password,
        });

        if (response.data.status) {
          dispatch({
            type: AuthType.SET_USER,
            payload: response.data.data.id,
            token: response.data.data.token,
          });
          return response;
        } else {
          return { error: response.data.message || "Invalid Credentials !" };
        }
      } catch (error: any) {
        return {
          error: error.response?.data?.message || "Something went wrong",
        };
      }
    },

  panelLogIn:
    (id: any): ThunkAction<Promise<any>, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const response: any = await axios.get(BASE_URL + "panel-login/" + id, {
          headers: {
            Accept: "application/json",
          },
        });

        if (response.data.status) {
          dispatch({
            type: AuthType.SET_USER,
            payload: response.data.data.id,
            token: response.data.data.token,
          });
          return response;
        } else {
          return { error: response.data.msg || "Invalid Credentials !" };
        }
      } catch (error: any) {
        return {
          error: error.response?.data?.message || "Something went wrong",
        };
      }
    },

  logout:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      dispatch({
        type: AuthType.RESET_AUTH,
      });
    },
};

export default AuthAction;
