import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import SalaryAction from "../../stores/action/salaryAction";
import PaymentModal from "../../components/PaymentModal";
import toast from "react-hot-toast";
import FilterSorting from "../../components/FilterSorting";
import Breadcrumb from "../../components/Breadcrumb";
import TableLoader from "../../components/TableLoader";
import Pagination from "../../components/Pagination";
import SalaryStructureModal from "../../components/SalaryStructureModal";
import moment from "moment";

function SalaryGenerateList() {
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [salaryModal, setSalaryModal] = useState<boolean>(false);
  const [salaryData, setSalaryData] = useState({
    attendanceSalary: 0,
    grossSalary: 0,
  });

  const dispatch = useDispatch<any>();

  const {
    salaryList,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
    salaryStructure,
    salaryPercentage,
    salaryDate,
  } = useSelector((state: RootState) => state.salary);

  const maxPagesToShow = 5;
  const pagination = [];
  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);
  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getSalaryRequest = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        SalaryAction.getSalaryGenerate({
          limit,
          search,
          page,
        }),
      );
      await dispatch(SalaryAction.getSalaryStructure());
      await dispatch(SalaryAction.getSalaryPercentage());
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getSalaryRequest();
  }, [getSalaryRequest, search, limit, page]);

  const handleSelectRow = (id: number) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === salaryList.length);
  };

  const handleSelectAll = () => {
    // Check if any unpaid rows exist
    const unpaidRows = salaryList
      .filter((data: any) => !data.salaryPaid)
      .map((data: any) => data.emp_no);

    if (!selectAll) {
      // If not selected, set selectedRows to only unpaid rows
      setSelectedRows(unpaidRows);
    } else {
      // If already selected, clear selectedRows
      setSelectedRows([]);
    }
    setSelectAll(!selectAll);
  };

  const calculateTotal = (field: string) =>
    selectedRows.reduce((total, rowId) => {
      const selectedData = salaryList.find(
        (dataList: any) => dataList.emp_no === rowId,
      );
      return total + (selectedData ? selectedData.salaryInfo[field] : 0);
    }, 0);

  const totalAmount = calculateTotal("salary");
  const totalAmountReceivable = calculateTotal("amount_receivable");
  const totalServiceFee = calculateTotal("service_fee");
  const totalGstAmount = calculateTotal("gst_amount");
  const paidTotalAmountReceivable = salaryList
    .filter((item: any) => item.salaryPaid)
    .reduce(
      (total: any, item: any) => total + item.salaryInfo.amount_receivable,
      0,
    );

  const openModal = () => {
    if (selectedRows.length === 0) {
      toast.error("Please select at least one row before proceeding.", {
        className: "custom-toast",
        position: "top-center",
      });
      return;
    }
    setModal(true);
  };

  return (
    <WebLayout pageName={"SalaryGenerateList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Salary"} subTitle={"Salary Sheet"} />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <h5 className={"text-center"}>
                  {moment(salaryDate, "MM-YYYY").format("MMMM YYYY")}
                </h5>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>#</th>
                          <th>Employee Id</th>
                          <th>Name</th>
                          <th>Salary</th>
                          <th>Gross Salary</th>
                          <th>Amount Payable</th>
                          <th>Service Fee</th>
                          <th>GST Amount</th>
                          <th>Salary View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={10} />
                        ) : salaryList?.length > 0 ? (
                          salaryList.map((dataList: any, index: number) => (
                            <tr key={dataList.id}>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(
                                    dataList.emp_no,
                                  )}
                                  onChange={() =>
                                    handleSelectRow(dataList.emp_no)
                                  }
                                  disabled={dataList.salaryPaid}
                                />
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                {index + 1}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                {dataList.emp_no || "N/A"}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                {dataList.name}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                Rs. {dataList?.gross_salary}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                <label
                                  className={"label label-danger"}
                                  onClick={() => {
                                    setSalaryData({
                                      attendanceSalary:
                                        dataList.salaryInfo.salary,
                                      grossSalary: dataList.gross_salary,
                                    });
                                    setSalaryModal(true);
                                  }}
                                >
                                  Rs. {dataList.salaryInfo?.salary?.toFixed(2)}
                                </label>
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                <label className={"label label-success"}>
                                  Rs.{" "}
                                  {dataList.salaryInfo?.amount_receivable?.toFixed(
                                    2,
                                  )}
                                </label>
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                Rs.{" "}
                                {dataList.salaryInfo?.service_fee?.toFixed(2)}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                Rs.{" "}
                                {dataList.salaryInfo?.gst_amount?.toFixed(2)}
                              </td>
                              <td
                                className={
                                  dataList.salaryPaid
                                    ? "table-row-success"
                                    : "table-row-default"
                                }
                              >
                                <button
                                  className={"btn btn-success btn-sm"}
                                  onClick={() => {
                                    setSalaryData({
                                      attendanceSalary:
                                        dataList.salaryInfo.salary,
                                      grossSalary: dataList.gross_salary,
                                    });
                                    setSalaryModal(true);
                                  }}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {salaryList?.length > 0 && (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  )}
                </div>
                <div className="row p-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    {totalAmountReceivable > 0 ? (
                      <h5 className={"text-danger"}>
                        Total Amount Payable: Rs. {totalAmountReceivable}
                      </h5>
                    ) : null}
                    {paidTotalAmountReceivable > 0 ? (
                      <h5 className={"text-success"}>
                        Total Amount Paid: Rs. {paidTotalAmountReceivable}
                      </h5>
                    ) : null}

                    {!salaryList.every((data: any) => data.salaryPaid) && (
                      <button
                        className="btn btn-success justify-content-end"
                        onClick={openModal}
                      >
                        PROCEED TO PAYMENT
                      </button>
                    )}
                  </div>
                </div>
                <PaymentModal
                  modal={modal}
                  setModal={setModal}
                  getSalaryRequest={getSalaryRequest}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  setSelectAll={setSelectAll}
                  totalAmount={totalAmount}
                  totalGstAmount={totalGstAmount}
                  totalServiceFee={totalServiceFee}
                  totalAmountReceivable={totalAmountReceivable}
                />
                <SalaryStructureModal
                  modal={salaryModal}
                  setModal={setSalaryModal}
                  salaryData={salaryData}
                  salaryStructure={salaryStructure}
                  salaryPercentage={salaryPercentage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default SalaryGenerateList;
