import React, { useState } from "react";
import { Dropzone } from "@files-ui/react";
import { getCookie } from "../../utils/CookieAction";
import {
  BASE_URL,
  BULK_UPLOAD_EXCEL,
  EMPLOYER_TOKEN,
} from "../../utils/Constants";
import toast from "react-hot-toast";

function BulkEmployeeModal({ modal, setModal, getEmployeeList }: any) {
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState<any>({
    type: "",
    message: "",
  });

  const handleFileUpload = async (files: File[]) => {
    if (files.length > 0) {
      setUploading(true);
      setMessage("");

      const token: string | any = getCookie(EMPLOYER_TOKEN);
      if (!token) {
        toast.error("Unauthorized: No token found.");
        setUploading(false);
        return;
      }

      const formData = new FormData();
      formData.append("excel_file", files[0]);

      try {
        const response = await fetch(`${BASE_URL}employee/import`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        });

        if (!response.ok) {
          const resData = await response.json();
          setError({
            type: "text",
            message: resData.msg,
          });
        }

        const resData = await response.json();

        if (resData.status) {
          setMessage(resData.msg);
        } else {
          setError({
            type: "array",
            message: resData.data,
          });
        }
      } catch (error: any) {
        setError({
          type: "text",
          message: error.message,
        });
      } finally {
        setUploading(false);
        getEmployeeList();
      }
    }
  };

  // Close Steps
  const closeModal = () => {
    setModal(false);
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = BULK_UPLOAD_EXCEL;
    link.download = "Employee_Sheet.xlsx"; // Optional: Rename file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-dark">Bulk Upload Employee </h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <button
                  type="button"
                  className="btn btn-success btn-sm float-right mb-2"
                  onClick={downloadFile}
                >
                  Download File
                </button>
                <Dropzone
                  onChange={(files) =>
                    handleFileUpload(files.map((f: any) => f.file))
                  }
                  accept=".xlsx, .xls"
                  maxFiles={1}
                  label="Drag & Drop an Excel file here or click to upload"
                  minHeight="150px"
                />
                {uploading && <p className="text-gray">Uploading...</p>}
                {message && <p className="text-success">{message}</p>}
                {error && (
                  <>
                    {error.type === "array" ? (
                      error.message.map((msg: any, index: number) => (
                        <p
                          key={index}
                          className={`text-danger m-0 ${index === 0 && "mt-3"}`}
                        >
                          {msg}
                        </p>
                      ))
                    ) : (
                      <p className="text-danger">{error.message}</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkEmployeeModal;
