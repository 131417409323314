import { Link } from "react-router-dom";
import React from "react";

const Breadcrumb = ({
  mainTitle,
  subTitle,
  childTitle,
  firstButton,
  secondButton,
  haveButtons = false,
}: any) => (
  <div className="col-12 mb-3">
    <div className={"d-flex justify-content-between"}>
      <h3 className={"m-0"}>{mainTitle}</h3>
      {haveButtons && (
        <div>
          <button className="btn btn-dark btn-sm" onClick={firstButton}>
            ACTIVE LIST
          </button>
          <button
            className="btn btn-danger btn-sm m-l-5"
            onClick={secondButton}
          >
            INACTIVE LIST
          </button>
        </div>
      )}
    </div>
    <ul className="breadcrumb breadcrumb-style mb-0">
      <li className="breadcrumb-item active">
        <Link to="/dashboard">
          <i className="fas fa-home"></i> Home
        </Link>
      </li>
      <li className="breadcrumb-item active">{subTitle}</li>
      {childTitle ? (
        <li className="breadcrumb-item active">{childTitle}</li>
      ) : null}
    </ul>
  </div>
);

export default Breadcrumb;
