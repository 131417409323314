import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";

import AuthReducer from "./reducers/authReducers";
import WebReducers from "./reducers/webReducers";
import EmployerReducers from "./reducers/employerReducers";
import EmployeeReducers from "./reducers/employeeReducers";
import ProfileReducers from "./reducers/profileReducers";
import departmentReducers from "./reducers/departmentReducers";
import designationReducers from "./reducers/designationReducers";
import attendanceReducers from "./reducers/attendanceReducers";
import leaveReducers from "./reducers/leaveReducers";
import holidayReducers from "./reducers/holidayReducers";
import salaryReducers from "./reducers/salaryReducers";
import staffDutyReducers from "./reducers/staffDutyReducers";
import dashboardReducers from "./reducers/dashboardReducers";

const rootReducer = combineReducers({
  auth: AuthReducer,
  web: WebReducers,
  employer: EmployerReducers,
  employee: EmployeeReducers,
  profile: ProfileReducers,
  department: departmentReducers,
  designation: designationReducers,
  attendance: attendanceReducers,
  leave: leaveReducers,
  holiday: holidayReducers,
  salary: salaryReducers,
  staffDuty: staffDutyReducers,
  dashboard: dashboardReducers,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
