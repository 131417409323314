import React, { useCallback, useEffect, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import moment from "moment";
import AttendanceAction from "../../stores/action/attendanceAction";
import { today } from "../../utils/Constants";
import AttendanceRow from "../../components/AttendanceRow";
import LeaveAction from "../../stores/action/leaveAction";
import Breadcrumb from "../../components/Breadcrumb";
import AttendanceSteps from "../../components/AttendanceSteps";
import DashboardAction from "../../stores/action/dashboardAction";

function Attendance() {
  const dispatch = useDispatch<any>();

  const [selectedDate, setSelectedDate] = useState<string>(today);
  const [attendanceData, setAttendanceData] = useState<any>({}); // Track all attendance data here
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const { attendanceEmployeeList } = useSelector(
    (state: RootState) => state.attendance,
  );

  const { leaveTypeCount, holidayCount } = useSelector(
    (state: RootState) => state.dashboard,
  );

  const getAttendance = useCallback(async () => {
    await dispatch(
      AttendanceAction.getAttendanceEmployees({ date: selectedDate }),
    );
    await dispatch(LeaveAction.leaveFetch());
  }, [dispatch, selectedDate]);

  useEffect(() => {
    getAttendance();
  }, [getAttendance]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  // Function to update attendanceData from child component

  const updateAttendanceData = (emp_no: string, data: any) => {
    setAttendanceData((prevData: any) => {
      const newAttendanceData = {
        ...prevData,
        [emp_no]: data,
      };

      // Validate if submit button should be disabled
      const shouldDisable = Object.values(newAttendanceData).some(
        (entry: any) => {
          if (entry.isOnLeave) return !entry.leaveTypeId;
          return !entry.punchInTime;
        },
      );

      setIsSubmitDisabled(shouldDisable);
      return newAttendanceData;
    });
  };

  // Function to handle submission of all attendance
  const handleSubmitAttendance = async () => {
    try {
      // Create the payload in the required format
      const formattedAttendanceData = Object.keys(attendanceData).map(
        (emp_no) => {
          const data = attendanceData[emp_no];
          const isOnLeave = data.isOnLeave;
          return {
            emp_no: emp_no,
            date: selectedDate,
            punchInTime: isOnLeave ? null : data.punchInTime || null,
            punchOutTime: isOnLeave ? null : data.punchOutTime || null,
            inLocation: null,
            outLocation: null,
            attendanceType: isOnLeave ? "LEAVE" : "PRESENT",
            leaveTypeId: isOnLeave ? data.leaveTypeId : "",
            reason: isOnLeave ? data.reason : "",
          };
        },
      );

      await dispatch(
        AttendanceAction.submitAttendance(formattedAttendanceData),
      );
      getAttendance();
    } catch (error) {
      alert("Failed to submit attendance.");
    }
  };

  const getData = useCallback(async () => {
    await dispatch(DashboardAction.getLeaveTypeCount());
    await dispatch(DashboardAction.getHolidayCount());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const isModuleDisabled = leaveTypeCount === 0 || holidayCount === 0;

  return (
    <WebLayout pageName={"Attendance"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Attendance"} subTitle={"Mark Attendance"} />

            {isModuleDisabled && (
              <AttendanceSteps
                leaveTypes={leaveTypeCount}
                holiday={holidayCount}
              />
            )}

            <div className="row mt-2">
              <div className="col-2">
                <label>Select Date:</label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  max={today}
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{
                    backgroundColor: "white",
                    padding: "0px 10px 0px 10px",
                    border: "1px solid",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-8">
              <div className="card">
                <div className="header">
                  <h2>
                    Mark Attendance on{" "}
                    {moment(selectedDate).format("DD-MMM-YYYY")}
                  </h2>
                </div>

                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>EMA Id</th>
                          <th>Name</th>
                          <th>Punch In Time</th>
                          <th>Punch Out Time</th>
                          <th>On Leave</th>
                          <th>Type Of Leave</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendanceEmployeeList?.length > 0 ? (
                          attendanceEmployeeList.map((employee: any) => (
                            <AttendanceRow
                              key={employee.emp_no}
                              employee={employee}
                              onAttendanceChange={updateAttendanceData} // Pass down the update function
                            />
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7} className={"text-center"}>
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Submit Button */}
                  <button
                    className="btn btn-primary mt-4"
                    onClick={handleSubmitAttendance}
                    disabled={isSubmitDisabled || isModuleDisabled}
                  >
                    Submit Attendance
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Attendance;
