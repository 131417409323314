import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useCallback, useEffect } from "react";
import DashboardAction from "../../stores/action/dashboardAction";
import Breadcrumb from "../../components/Breadcrumb";
import EmployeeAction from "../../stores/action/employeeAction";
import { Link } from "react-router-dom";

function Home() {
  const dispatch = useDispatch<any>();
  const { departmentCount, designationCount, leaveTypeCount, holidayCount } =
    useSelector((state: RootState) => state.dashboard);

  const { employeeList = [] } = useSelector(
    (state: RootState) => state.employee,
  );

  const getData = useCallback(async () => {
    await dispatch(DashboardAction.getDepartmentCount());
    await dispatch(DashboardAction.getDesignationCount());
    await dispatch(DashboardAction.getLeaveTypeCount());
    await dispatch(DashboardAction.getHolidayCount());
  }, [dispatch]);

  const getEmployeeList = useCallback(async () => {
    await dispatch(
      EmployeeAction.getEmployeeList({
        limit: 1000,
        search: "",
        page: 1,
        type: "",
        employeeStatus: "",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
    getEmployeeList();
  }, [getData, getEmployeeList]);

  return (
    <WebLayout pageName={"Home"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"Dashboard"} subTitle={"Dashboard"} />
            </div>
          </div>

          <div className="row">
            <div className="row">
              <div className="dashboard_container">
                <div className="dashboard_box">
                  <div className="row m-l-0">
                    <div className="dashboard_content l-bg-cyan col-4">
                      <Link to={"/department"}>
                        <h1 className="text-dark m-0 mt-3">
                          {departmentCount}
                        </h1>
                        <h4 className="text-dark text-center">Departments</h4>
                      </Link>
                    </div>

                    <div className="dashboard_content l-bg-green-dark col-4">
                      <Link to={"/designation"}>
                        <h1 className="text-dark m-0 mt-3">
                          {designationCount}
                        </h1>
                        <h4 className="text-dark text-center">Designations</h4>
                      </Link>
                    </div>
                    <div className="dashboard_content l-bg-orange-dark col-4">
                      <Link to={"/leave"}>
                        <h1 className="text-dark m-0 mt-3">{leaveTypeCount}</h1>
                        <h4 className="text-dark text-center">Leave Types</h4>
                      </Link>
                    </div>
                    <div className="dashboard_content l-bg-purple-dark col-4">
                      <Link to={"/holiday"}>
                        <h1 className="text-dark m-0 mt-3">{holidayCount}</h1>
                        <h4 className="text-dark text-center">Holiday</h4>
                      </Link>
                    </div>
                    <div className="dashboard_content l-bg-sky-dark col-4">
                      <Link to={"/employee-list"}>
                        <h1 className="text-dark m-0 mt-3">
                          {employeeList.length}
                        </h1>
                        <h4 className="text-dark text-center">EMA Account</h4>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Home;
