import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const menuList = [
  {
    name: "DASHBOARD",
    subMenu: false,
    active: true,
    subMenuList: [],
    url: "/dashboard",
    icon: "fa fa-home",
    extraUrl: [],
  },

  {
    name: "DEPARTMENT",
    subMenu: false,
    active: true,
    subMenuList: [],
    url: "/department",
    icon: "fa fa-briefcase",
    extraUrl: [],
  },
  {
    name: "DESIGNATION",
    subMenu: false,
    active: true,
    subMenuList: [],
    url: "/designation",
    icon: "fa fa-briefcase",
    extraUrl: [],
  },
  {
    name: "EMA ACCOUNT",
    subMenu: true,
    active: true,
    icon: "fa fa-users",
    subMenuList: [
      {
        name: "EMA ACCOUNT LIST",
        active: true,
        url: "/employee-list",
        extraUrl: ["/employee-profile/"],
      },
      {
        name: "CREATE EMA ACCOUNT",
        active: true,
        url: "/create-employee",
        extraUrl: [],
      },
    ],
  },
  {
    name: "ATTENDANCE",
    subMenu: true,
    active: true,
    icon: "fa fa-user",
    subMenuList: [
      {
        name: "MARK ATTENDANCE",
        active: true,
        url: "/mark-attendance",
        extraUrl: [],
      },
      {
        name: "ATTENDANCE LIST",
        active: true,
        url: "/view-attendance",
        extraUrl: [],
      },
    ],
  },
  {
    name: "LEAVE",
    subMenu: true,
    active: true,
    icon: "fa fa-rocket",
    subMenuList: [
      {
        name: "LEAVE TYPES",
        active: true,
        url: "/leave",
        extraUrl: [],
      },
      {
        name: "LEAVE APPLICATIONS",
        active: true,
        url: "/leave-applications",
        extraUrl: [],
      },
    ],
  },
  {
    name: "HOLIDAYS",
    subMenu: false,
    active: true,
    subMenuList: [],
    url: "/holiday",
    icon: "fa fa-calendar",
    extraUrl: [],
  },
  {
    name: "SALARY",
    subMenu: true,
    active: true,
    icon: "fa fa-user",
    subMenuList: [
      {
        name: "SALARY SHEET",
        active: true,
        url: "/salary-generate",
        extraUrl: [],
      },
      {
        name: "SALARY PERCENTAGE",
        active: true,
        url: "/salary-percentage",
        extraUrl: [],
      },
    ],
  },
  // {
  //   name: "MANAGE STAFF DUTY",
  //   subMenu: true,
  //   active: true,
  //   icon: "fa fa-users",
  //   subMenuList: [
  //     {
  //       name: "STAFF SCHEDULE",
  //       active: true,
  //       url: "/staff-schedule",
  //       extraUrl: [],
  //     },
  //     {
  //       name: "ASSIGN STAFF DUTY",
  //       active: true,
  //       url: "/assign-staff-duty",
  //       extraUrl: [],
  //     },
  //   ],
  // },
];

function Sidebar() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState({
    parent: "Dashboard",
    child: "",
    icon: "fa fa-home",
  });
  const [openSubMenu, setOpenSubMenu] = useState("");

  useEffect(() => {
    const activeMenu = menuList.find((menu) => {
      if (menu.subMenu) {
        return menu.subMenuList.some((sub) => {
          const isUrlActive = location.pathname === sub.url;
          const isExtraUrlActive = sub.extraUrl.some(
            (extra) => location.pathname.startsWith(extra), // Check if the pathname starts with extraUrl item
          );
          return isUrlActive || isExtraUrlActive; // Active if either condition is true
        });
      }
      return location.pathname === menu.url;
    });

    if (activeMenu) {
      const activeSubMenu = activeMenu.subMenuList.find(
        (sub) =>
          sub.url === location.pathname ||
          sub.extraUrl.some((extra) => location.pathname.startsWith(extra)), // Check for extraUrl match
      );

      setActiveTab({
        parent: activeMenu.name,
        child: activeSubMenu ? activeSubMenu.name : "",
        icon: activeMenu.icon,
      });
      setOpenSubMenu(activeMenu.subMenu ? activeMenu.name : "");
    } else {
      setActiveTab({
        parent: "",
        child: "",
        icon: "",
      });
      setOpenSubMenu("");
    }
  }, [location.pathname]);

  const handleMenuClick = (menu: any) => {
    if (menu.subMenu) {
      setOpenSubMenu(openSubMenu === menu.name ? "" : menu.name);
    } else {
      setOpenSubMenu("");
    }
  };

  return (
    <div>
      <aside
        id="leftsidebar"
        className="sidebar bg-gray-900 text-white h-screen w-64"
        style={{ borderRight: "1px solid #e7e7e7" }}
      >
        <div className="menu">
          <ul className="list">
            {menuList.map((item, menuIndex) => (
              <li key={menuIndex} className="mb-2">
                <Link
                  to={`${item.subMenu ? "#" : item.url}`}
                  className={`flex items-center gap-3 p-3 rounded-lg transition-all ${item.subMenu ? "menu-toggle" : ""} ${
                    activeTab.parent === item.name
                      ? "bg-blue-600 text-white bg-dark"
                      : "hover:bg-gray-800 text-gray-300"
                  }`}
                  onClick={() => handleMenuClick(item)}
                >
                  <i className={`${item.icon} text-lg`}></i>
                  <span
                    className={`font-bold sideHeading ${
                      activeTab.parent === item.name ? "text-white" : ""
                    }`}
                  >
                    {item.name}
                  </span>
                </Link>

                {item.subMenu && (
                  <ul
                    className="ml-4 border-l-2 border-gray-700 ml-menu"
                    style={{
                      display: openSubMenu === item.name ? "block" : "none",
                    }}
                  >
                    {item.subMenuList.map((subItem, subMenuIndex) => (
                      <li key={subMenuIndex} className="mt-1">
                        <Link
                          to={subItem.url ?? "#"}
                          className={`block p-2 pl-5 rounded-md transition-all m-l-30 font-bold${
                            activeTab.child === subItem.name
                              ? "bg-blue-500 text-white bg-submenu"
                              : "hover:bg-gray-800 text-gray-400"
                          }`}
                        >
                          {subItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
