import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import { EMPLOYER_TOKEN, BASE_URL } from "../../utils/Constants";
import axios from "axios";
import WebType, { GetDistrict } from "../type/webType";
import toast from "react-hot-toast";
import { getCookie } from "../../utils/CookieAction";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const WebAction = {
  getState:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = getCookie(EMPLOYER_TOKEN);
        const result: any = await axios.get(BASE_URL + "state", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });
        dispatch({
          type: WebType.GET_STATE_LIST,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error: any) {
        toast.error(error, {
          className: "custom-toast",
        });
      }
    },

  getDistrict:
    (data: GetDistrict): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = getCookie(EMPLOYER_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "district/" + data.state_id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        dispatch({
          type: WebType.GET_DISTRICT_LIST,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error: any) {
        toast.error(error, {
          className: "custom-toast",
        });
      }
    },

  getCompanyType:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = getCookie(EMPLOYER_TOKEN);
        const result: any = await axios.get(BASE_URL + "companyType", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });
        dispatch({
          type: WebType.GET_COMPANY_TYPE_LIST,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error: any) {
        toast.error(error, {
          className: "custom-toast",
        });
      }
    },

  getDepartment:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = getCookie(EMPLOYER_TOKEN);
        const result: any = await axios.get(BASE_URL + "employee/department", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });
        dispatch({
          type: WebType.GET_DEPARTMENT,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error: any) {
        toast.error(error, {
          className: "custom-toast",
        });
      }
    },

  getDesignation:
    (id: any): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = getCookie(EMPLOYER_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "employee/designation/" + id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );
        dispatch({
          type: WebType.GET_DESIGNATION,
          payload: {
            data: result.data.data,
          },
        });
      } catch (error: any) {
        toast.error(error, {
          className: "custom-toast",
        });
      }
    },
};

export default WebAction;
