import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import DesignationAction from "../stores/action/designationAction";
import { RootState } from "../stores";

type FormData = {
  departmentId: string;
  name: string;
};

interface AddDesignationModalProps {
  designationModal: boolean;
  setDesignationModal: (value: boolean) => void;
  getDesignation: (departmentId: any) => Promise<void>;
  deptId: string;
}

const AddDesignationModal: React.FC<AddDesignationModalProps> = ({
  designationModal,
  setDesignationModal,
  getDesignation,
  deptId,
}) => {
  const dispatch = useDispatch<any>();
  const { employeeDepartmentList } = useSelector(
    (state: RootState) => state.web,
  );
  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
      departmentId: "",
    },
  });

  const { register, reset } = methods;

  // Submit Handlers
  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await dispatch(
        DesignationAction.createDesignation({
          name: data.name,
          departmentId: data.departmentId,
        }),
      );
      reset();
      setLoading(false);
      getDesignation(deptId);
    } catch (error) {
      setLoading(false);
    }
  };

  // Close Steps
  const closeDesignationModal = () => {
    setDesignationModal(false);
  };

  return (
    <div
      className="modal"
      style={{ display: designationModal ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <form
          className="needs-validation"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Add Designation</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeDesignationModal}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Department <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("departmentId", { required: true })}
                      required
                    >
                      <option value="" disabled>
                        Select Department
                      </option>
                      {employeeDepartmentList?.map(
                        (val: any, index: number) => (
                          <option key={index} value={val.id}>
                            {val.name}
                          </option>
                        ),
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Designation Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Designation Name"
                      {...register("name", { required: true })}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                disabled={loading}
                onClick={closeDesignationModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className="fa fa-spinner fa-spin"></i> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDesignationModal;
