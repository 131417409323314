// export const BASE_URL = "http://localhost/asl_api/api/v1/employer/";
export const BASE_URL = "https://abhhyamsecure.com/api/api/v1/employer/";
export const EMPLOYER_USER_ID = "@ASL_EMPLOYER:EMPLOYER_USER_ID";
export const EMPLOYER_TOKEN = "@ASL_EMPLOYER_TOKEN:EMPLOYER_TOKEN";
export const today = new Date().toISOString().split("T")[0];
export const LOGO = "../assets/images/asl_logo.png";
export const LOGIN_IMG =
  "../assets/images/hand-drawn-man-working-from-home-b.png";
export const BULK_UPLOAD_EXCEL = "../assets/excel/Employee Sheet.xlsx";
export const CHEQUE_IMG = "../assets/images/cheque.jpeg";
