import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import AttendanceAction from "../../stores/action/attendanceAction";
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumb";
import FilterSorting from "../../components/FilterSorting";
import ImageComponent from "../../components/ImageComponent";
import Pagination from "../../components/Pagination";
import TableLoader from "../../components/TableLoader";

function ViewAttendance() {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loading, setLoading] = useState<boolean>(false);

  // Set the first and last date of the current month by default
  useEffect(() => {
    // Get current month
    const currentMonth = moment().month();
    const currentYear = moment().year();

    // Calculate the first date of the current month (1st day)
    const firstDateOfMonth = moment([currentYear, currentMonth, 1]).format(
      "YYYY-MM-DD",
    );

    // Calculate the last date of the current month
    const lastDateOfMonth = moment(firstDateOfMonth)
      .endOf("month")
      .format("YYYY-MM-DD");

    // Set the start and end dates
    setStartDate(firstDateOfMonth);
    setEndDate(lastDateOfMonth);
  }, []);

  // Handle start date change
  const handleStartDateChange = (e: any) => {
    setStartDate(e.target.value);
  };

  // Handle end date change
  const handleEndDateChange = (e: any) => {
    setEndDate(e.target.value);
  };

  const { viewAttendanceList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.attendance);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getViewAttendance = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        AttendanceAction.viewAttendance({
          limit: limit,
          search: search,
          page: page,
          startDate: startDate,
          endDate: endDate,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page, startDate, endDate]);

  useEffect(() => {
    getViewAttendance();
  }, [getViewAttendance, search, limit, page, startDate, endDate]);

  return (
    <WebLayout pageName={"ViewAttendance"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Attendance"} subTitle={"Attendance List"} />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>Attendance Summary</h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <label>From Date:</label>
                      <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        placeholder="Select start date"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid",
                          marginTop: "2 rem",
                          padding: "0 10px",
                          width: "90%",
                        }}
                      />
                    </div>
                    <p className={"text-black"} style={{ marginTop: 25 }}>
                      <strong>&emsp;to&emsp;</strong>
                    </p>
                    <div>
                      <label>To Date:</label>
                      <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        placeholder="Select end date"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid",
                          padding: "0 10px",
                          width: "90%",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Employee Details</th>
                          <th> Image</th>
                          {/*<th> Last Absent</th>*/}
                          <th> Leaves</th>
                          <th> Account Status</th>
                          <th> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={7} />
                        ) : viewAttendanceList?.length > 0 ? (
                          viewAttendanceList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <b>Name :-</b>{" "}
                                    {dataList.name ? dataList.name : "N/A"}
                                    <br />
                                    <b>Mobile No. :-</b> {dataList.mobile}
                                  </td>
                                  <td>
                                    <ImageComponent
                                      sourceImage={dataList.profile_img}
                                      width={80}
                                      height={80}
                                      altTitle={"Profile Image"}
                                    />
                                  </td>
                                  {/*<td>2 days ago</td>*/}
                                  <td>
                                    {dataList?.attendance.map(
                                      (item: any, index: number) => {
                                        return (
                                          <table key={index}>
                                            {Object.entries(item).map(
                                              ([key, val]) => (
                                                <tr
                                                  style={{
                                                    border: "1px solid #ccc",
                                                  }}
                                                >
                                                  <th
                                                    style={{
                                                      width: "50%",
                                                      backgroundColor:
                                                        "#cbcbff",
                                                      border: "unset",
                                                      padding: 10,
                                                      color: "#24245c",
                                                    }}
                                                  >
                                                    {key}
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "50%",
                                                      backgroundColor:
                                                        "#e4e4f3",
                                                      border: "unset",
                                                      padding: 0,
                                                      color: "#24245c",
                                                    }}
                                                  >
                                                    {typeof val === "string" ||
                                                    typeof val === "number"
                                                      ? val
                                                      : JSON.stringify(val)}
                                                  </th>
                                                </tr>
                                              ),
                                            )}
                                          </table>
                                        );
                                      },
                                    )}
                                  </td>
                                  <td>
                                    <label
                                      className={`label label-sm ${dataList.employee_status === "PENDING" ? "label-warning" : dataList.employee_status === "ACTIVE" ? "label-info" : "label-danger"}`}
                                      style={{ borderRadius: "0 !important" }}
                                    >
                                      {dataList.employee_status}
                                    </label>
                                  </td>
                                  <td>
                                    <Link
                                      to={`../employee-attendance/${dataList.id}`}
                                    >
                                      <button
                                        className={"btn btn-sm btn-success"}
                                        style={{ borderRadius: "0 !important" }}
                                      >
                                        View
                                      </button>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {viewAttendanceList?.length > 0 && (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default ViewAttendance;
