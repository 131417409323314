import DashboardType from "../type/dashboardType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  departmentCount: 0,
  designationCount: 0,
  salaryStructureStatus: false,
  leaveTypeCount: 0,
  holidayCount: 0,
};

const DashboardReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case DashboardType.GET_DEPARTMENT_COUNT:
      return {
        ...state,
        departmentCount: action.payload,
      };

    case DashboardType.GET_DESIGNATION_COUNT:
      return {
        ...state,
        designationCount: action.payload,
      };

    case DashboardType.GET_SALARY_STRUCTURE_STATUS:
      return {
        ...state,
        salaryStructureStatus: action.payload,
      };

    case DashboardType.GET_LEAVE_TYPE_COUNT:
      return {
        ...state,
        leaveTypeCount: action.payload,
      };

    case DashboardType.GET_HOLIDAY_COUNT:
      return {
        ...state,
        holidayCount: action.payload,
      };

    default:
      return state;
  }
};

export default DashboardReducers;
