import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useForm } from "react-hook-form";
import DesignationAction from "../../stores/action/designationAction";
import swal from "sweetalert";
import Breadcrumb from "../../components/Breadcrumb";
import FilterSorting from "../../components/FilterSorting";
import TableLoader from "../../components/TableLoader";
import Pagination from "../../components/Pagination";
import WebAction from "../../stores/action/webAction";

type FormData = {
  departmentId: string;
  name: string;
};

function Designation() {
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [editId, setEditId] = useState<number | null>(null);
  const [editedName, setEditedName] = useState<string>("");

  const dispatch = useDispatch<any>();

  const { designationList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.designation,
  );

  const { employeeDepartmentList } = useSelector(
    (state: RootState) => state.web,
  );

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
      departmentId: "",
    },
  });

  const { register, reset } = methods;

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getDesignationList = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(
        DesignationAction.getDesignationList({
          limit: limit,
          search: search,
          page: page,
        }),
      );
    } finally {
      setDataLoading(false);
    }
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    dispatch(WebAction.getDepartment());
    getDesignationList();
  }, [dispatch, getDesignationList]);

  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await dispatch(
        DesignationAction.createDesignation({
          name: data.name,
          departmentId: data.departmentId,
        }),
      );
      reset();
      setLoading(false);
      setPage(1); // Optionally reset to first page after adding
      getDesignationList();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemove = async (id: number) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "delete-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
      },
    });

    if (willDelete) {
      try {
        setLoading(true);
        await dispatch(DesignationAction.removeDesignation({ id }));
        swal("Your data has been deleted!", {
          icon: "success",
        });
        getDesignationList();
      } catch (error) {
        swal("Error!", "There was an issue deleting the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (id: number, currentName: string) => {
    setEditId(id); // Set the current row for editing
    setEditedName(currentName); // Set the current name in the input field
  };

  // Handle update
  const handleUpdate = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(
        DesignationAction.updateDesignation({
          id: id,
          name: editedName,
        }),
      );
      setEditId(null); // Close the edit mode after updating
      getDesignationList();
    } catch (error) {
      console.error("Error updating designation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WebLayout pageName={"Designation"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Designation"} subTitle={"Designations"} />
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="card">
                <div className="body">
                  <div className="row clearfix">
                    <form
                      className="needs-validation"
                      onSubmit={methods.handleSubmit(onSubmit)}
                      encType="multipart/form-data"
                    >
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Department <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            {...register("departmentId", { required: true })}
                            required
                          >
                            <option value="" disabled>
                              Select Department
                            </option>
                            {employeeDepartmentList?.map(
                              (val: any, index: number) => (
                                <option key={index} value={val.id}>
                                  {val.name}
                                </option>
                              ),
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Designation Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Designation Name"
                            {...register("name", { required: true })}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-dark ml-auto"
                          disabled={loading}
                        >
                          {loading ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* StaffDuty List */}
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="card">
                <div className="header">
                  <h2>ALL DESIGNATION LIST</h2>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Department Name</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLoading ? (
                          <TableLoader column={4} />
                        ) : designationList?.length > 0 ? (
                          designationList.map(
                            (dataList: any, index: number) => (
                              <tr key={dataList.id}>
                                <td>{from + index}</td>
                                <td>
                                  <label className={"label label-primary"}>
                                    {dataList.departmentName}
                                  </label>
                                </td>
                                <td>
                                  {editId === dataList.id ? (
                                    <input
                                      type="text"
                                      value={editedName}
                                      onChange={(e) =>
                                        setEditedName(e.target.value)
                                      }
                                    />
                                  ) : (
                                    dataList.name
                                  )}
                                </td>
                                <td>
                                  {editId === dataList.id ? (
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={() => handleUpdate(dataList.id)}
                                    >
                                      Update
                                    </button>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() =>
                                          handleEdit(dataList.id, dataList.name)
                                        }
                                      >
                                        Edit
                                      </button>
                                      &nbsp;
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() =>
                                          handleRemove(dataList.id)
                                        }
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <i className="fa fa-spinner fa-spin"></i>
                                        ) : (
                                          "Remove"
                                        )}
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ),
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {designationList?.length > 0 && (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Designation;
