import { useState, useEffect } from "react";
import { UseFormSetValue, UseFormSetError } from "react-hook-form";
import { BASE_URL } from "../utils/Constants";

type FormData = {
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
};

export const useFetchBankDetails = (
  ifscCode: string,
  setValue: UseFormSetValue<FormData>,
  setError: UseFormSetError<FormData>,
) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ifscCode?.trim().length === 11) {
      fetchBankDetails(ifscCode);
    } else {
      setValue("bankName", "");
      setValue("branchName", "");
    }
  }, [ifscCode]);

  const fetchBankDetails = async (ifsc: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}match-ifsc-code/${ifsc}`);
      const { data } = await response.json();

      if (data && data.bankName && data.branch) {
        setValue("bankName", data.bankName);
        setValue("branchName", data.branch);
        setError("ifscCode", { type: "manual", message: "" });
      } else {
        setError("ifscCode", { type: "manual", message: "Invalid IFSC Code" });
        setValue("bankName", "");
        setValue("branchName", "");
      }
    } catch (error) {
      setError("ifscCode", { type: "manual", message: "Invalid IFSC Code" });
      setValue("bankName", "");
      setValue("branchName", "");
    } finally {
      setLoading(false);
    }
  };

  return { loading };
};
