import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import swal from "sweetalert";
import HolidayAction from "../../stores/action/holidayAction";
import Breadcrumb from "../../components/Breadcrumb";
import moment from "moment";
import FilterSorting from "../../components/FilterSorting";
import AddHolidayModal from "../../components/AddHolidayModal";
import TableLoader from "../../components/TableLoader";
import Pagination from "../../components/Pagination";

function Holiday() {
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [editId, setEditId] = useState<number | null>(null); // State to track which row is being edited
  const [editedName, setEditedName] = useState<string>(""); // State for the new name input field
  const [editedDate, setEditedDate] = useState<string>(""); // State for the new name input field

  const [selectedMonth, setSelectedMonth] = useState("");
  const [modal, setModal] = useState<boolean>(false);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dispatch = useDispatch<any>();

  const { holidayList, lastPage, totalData, from, to, checkSunday } =
    useSelector((state: RootState) => state.holiday);

  useEffect(() => {
    setSelectedMonth(moment().format("MMMM"));
  }, []);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getHolidayList = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        HolidayAction.getHolidayList({
          limit: limit,
          search: search,
          page: page,
          month: selectedMonth,
        }),
      );
    } finally {
      setLoading(false);
    }
    await dispatch(HolidayAction.checkSunday());
  }, [dispatch, limit, search, page, selectedMonth]);

  useEffect(() => {
    getHolidayList();
  }, [getHolidayList]);

  const handleRemove = async (id: number) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "delete-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
      },
    });

    if (willDelete) {
      try {
        setLoading(true);
        await dispatch(HolidayAction.removeHoliday({ id }));
        swal("Your data has been deleted!", {
          icon: "success",
        });
        getHolidayList();
      } catch (error) {
        swal("Error!", "There was an issue deleting the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (id: number, currentName: string, currentDate: string) => {
    setEditId(id); // Set the current row for editing
    setEditedName(currentName); // Set the current name in the input field
    setEditedDate(currentDate); // Set the current name in the input field
  };

  // Handle update
  const handleUpdate = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(
        HolidayAction.updateHoliday({
          id: id,
          name: editedName,
          date: editedDate,
        }),
      );
      setEditId(null); // Close the edit mode after updating
      getHolidayList();
    } catch (error) {
      console.error("Error updating holiday:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await dispatch(HolidayAction.markAllSunday());
      await dispatch(HolidayAction.checkSunday());
      getHolidayList();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <WebLayout pageName={"Holiday"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Holidays"}
                subTitle={"Holidays"}
                childTitle={`Holiday List Of ${moment().format("Y")}`}
              />
            </div>
          </div>
          <div className={"d-flex justify-content-between mb-2"}>
            <button className="btn btn-success" onClick={() => setModal(true)}>
              Add Holidays <i className={"fa fa-plus iconFontSize"}></i>
            </button>
            {checkSunday ? (
              <button className="btn btn-success" onClick={onSubmit}>
                Mark All Sunday Holiday &nbsp;
                <i className={"fa fa-check iconFontSize"}></i>
              </button>
            ) : null}
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-4">
              <div className={"card"}>
                <div className={"body"}>
                  <div className={"row"}>
                    <div className="month-list">
                      {months.map((month) => (
                        <div
                          key={month}
                          className={`month-item ${selectedMonth === month ? "active" : ""}`}
                          onClick={() => setSelectedMonth(month)}
                        >
                          <i className="calendar-icon fa fa-calendar-alt" />
                          {month}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddHolidayModal
              modal={modal}
              setModal={setModal}
              getHolidayList={getHolidayList}
            />

            {/* Holiday List */}
            <div className="col-lg-9 col-md-9 col-sm-9">
              <div className="card">
                <div className="header">
                  <h2>ALL HOLIDAY LIST</h2>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Occasion</th>
                          <th>Day</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={5} />
                        ) : holidayList?.length > 0 ? (
                          holidayList.map((dataList: any, index: number) => (
                            <tr key={dataList.id}>
                              <td>{from + index}</td>
                              <td>
                                {editId === dataList.id ? (
                                  <input
                                    type="date"
                                    value={editedDate}
                                    onChange={(e) =>
                                      setEditedDate(e.target.value)
                                    }
                                  />
                                ) : (
                                  moment(dataList.date).format("DD-MMM-YYYY")
                                )}
                              </td>
                              <td>
                                {editId === dataList.id ? (
                                  <input
                                    type="text"
                                    value={editedName}
                                    onChange={(e) =>
                                      setEditedName(e.target.value)
                                    }
                                  />
                                ) : (
                                  dataList.name
                                )}
                              </td>
                              <td>{moment(dataList.date).format("ddd")}</td>
                              <td>
                                {editId === dataList.id ? (
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => handleUpdate(dataList.id)}
                                  >
                                    Update
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={() =>
                                        handleEdit(
                                          dataList.id,
                                          dataList.name,
                                          dataList.date,
                                        )
                                      }
                                    >
                                      Edit
                                    </button>
                                    &nbsp;
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => handleRemove(dataList.id)}
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      ) : (
                                        "Remove"
                                      )}
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {holidayList?.length > 0 && (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Holiday;
