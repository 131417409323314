const Cookies = require("js-cookie");

export const storeCookie = (name: string, value: any) => {
  Cookies.set(name, value, {
    domain: ".abhhyamsecure.com",
    expires: 1,
    path: "/",
  });
};

export const getCookie = (name: string) => {
  return Cookies.get(name, {
    domain: ".abhhyamsecure.com",
    expires: 1,
    path: "/",
  });
};

export const removeCookie = (name: string) => {
  return Cookies.remove(name, {
    domain: ".abhhyamsecure.com",
    expires: 1,
    path: "/",
  });
};
