import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores";
import { useCallback, useEffect } from "react";
import EmployerAction from "../stores/action/employerAction";
import { LOGO } from "../utils/Constants";

function Header({ logoutHandle }: any) {
  const dispatch = useDispatch<any>();

  const { employerDetails } = useSelector((state: RootState) => state.employer);

  const getProfileData = useCallback(async () => {
    await dispatch(EmployerAction.getEmployerDetails());
  }, [dispatch]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  return (
    <nav className="navbar">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div
          className="navbar-header d-flex align-items-center"
          style={{ borderRight: "1px solid #e7e7e7" }}
        >
          <Link
            to="#"
            className="navbar-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-collapse"
            aria-expanded="false"
            style={{ color: "black" }}
          />
          <Link to="#" className="bars" />
          <Link className="navbar-brand" to="/dashboard">
            <img
              className="logo-name manage_logo"
              src={LOGO}
              alt="Abhhyam Secured Life"
            />
          </Link>
        </div>

        {/* Centered Text */}
        <div className="text-center flex-grow-1">
          <h4 className="m-0 p-0 text-white">
            EMC PANEL ({employerDetails.emp_no})
          </h4>
          <h6 className="m-0 p-0 text-white">
            ({employerDetails.name || "Guest"})
          </h6>
        </div>

        <ul className="nav navbar-nav navbar-right">
          <li className="dropdown user_profile">
            <div className="dropdown-toggle" data-bs-toggle="dropdown">
              <img
                src={
                  employerDetails?.company_image ||
                  "https://admin.abhhyamsecure.com/assets/images/asl_logo.jpeg"
                }
                alt={"user"}
                width={50}
                height={50}
              />
            </div>

            <ul className="dropdown-menu pullDown">
              <li className="body" style={{ float: "none" }}>
                <ul className="user_dw_menu">
                  <li>
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => logoutHandle()}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
