import React, { useEffect, useState } from "react";
import { EMPLOYER_TOKEN, LOGIN_IMG, LOGO } from "../../utils/Constants";
import "../../login.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import AuthAction from "../../stores/action/authAction";
import { getCookie } from "../../utils/CookieAction"; // Custom CSS

type FormData = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()<any>;

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { register, handleSubmit, formState } = useForm<FormData>();
  const { isSubmitting } = formState;
  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    const storedToken = getCookie(EMPLOYER_TOKEN);
    if ((authenticated && token) || storedToken) {
      navigate("/dashboard");
    }
  }, [authenticated, navigate, token]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await dispatch(
        AuthAction.logIn({ email: data.email, password: data.password }),
      );

      if (response?.error) {
        setErrorMessage(response.error);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      } else {
        setErrorMessage(null);
        toast.success("Login Successful!");
        navigate("/dashboard");
      }
    } catch (error) {
      setTimeout(() => {
        setErrorMessage("Something went wrong. Please try again.");
      }, 2000);
    }
  });

  return (
    <div className="login-container">
      {/* Left Section */}
      <div className="left-section">
        <img src={LOGIN_IMG} alt="Login Illustration" />
      </div>

      {/* Right Section */}
      <div className="right-section">
        <div className="login-card">
          <img src={LOGO} alt="Shop Logo" className="" />
          <h2>Welcome Back!</h2>
          <p>Please login to your account</p>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form onSubmit={onSubmit}>
            <div className="input-group">
              <i className={"fa fa-envelope icon"}></i>
              <input
                type="email"
                placeholder="email"
                className="borderBottom"
                {...register("email")}
                required
              />
            </div>
            <div className="input-group password-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="borderBottom"
                {...register("password")}
                required
              />
              <i
                className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"} icon`}
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            </div>

            <button
              className={`login-btn ${isSubmitting ? "disabled-btn" : ""}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? <div className="loader"></div> : " Sign In"}
            </button>
          </form>
          <p className="font-bold mt-3 mb-0">
            Don't have an account?{" "}
            <a href="https://abhhyamsecure.com/emc-registration">
              Create a new account now
            </a>
            . It's FREE!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
