import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import DepartmentAction from "../stores/action/departmentAction";

type FormData = {
  name: string;
};

function AddDepartmentModal({
  departmentModal,
  setDepartmentModal,
  getDepartmentList,
}: any) {
  const dispatch = useDispatch<any>();

  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  const { register, reset } = methods;

  // Submit Handlers
  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await dispatch(
        DepartmentAction.createDepartment({
          name: data.name,
        }),
      );
      setLoading(false);
      getDepartmentList();
      reset();
    } catch (error) {
      setLoading(false);
    }
  };

  // Close Steps
  const closeDepartmentModal = () => {
    setDepartmentModal(false);
  };

  return (
    <div
      className="modal"
      style={{ display: departmentModal ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <form
          className="needs-validation"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Add Department</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeDepartmentModal}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Department Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Department Name"
                      {...register("name", { required: true })}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                disabled={loading}
                onClick={closeDepartmentModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className="fa fa-spinner fa-spin"></i> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddDepartmentModal;
