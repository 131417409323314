import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import PageRoutes from "./routes/PageRoutes";
import { Toaster } from "react-hot-toast";
import KYCModal from "./components/KYCModal";
import { useSelector } from "react-redux";
import { RootState } from "./stores";

function App() {
  const employerDetails = useSelector(
    (state: RootState) => state.employer?.employerDetails || {},
  );

  return (
    <BrowserRouter>
      <Toaster position="top-right" reverseOrder={false} />
      <PageRoutes />
      {typeof employerDetails?.profile_steps === "string" &&
        employerDetails?.profile_steps !== "COMPLETE" && <KYCModal />}
    </BrowserRouter>
  );
}

export default App;
