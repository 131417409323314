import { useParams } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import AttendanceAction from "../../stores/action/attendanceAction";
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumb";

function EmployeeAttendance() {
  const { id } = useParams<any>();
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Set the first and last date of the current month by default
  useEffect(() => {
    // Get current month
    const currentMonth = moment().month();
    const currentYear = moment().year();

    // Calculate the first date of the current month (1st day)
    const firstDateOfMonth = moment([currentYear, currentMonth, 1]).format(
      "YYYY-MM-DD",
    );

    // Calculate the last date of the current month
    const lastDateOfMonth = moment(firstDateOfMonth)
      .endOf("month")
      .format("YYYY-MM-DD");

    // Set the start and end dates
    setStartDate(firstDateOfMonth);
    setEndDate(lastDateOfMonth);
  }, []);

  // Handle start date change
  const handleStartDateChange = (e: any) => {
    setStartDate(e.target.value);
  };

  // Handle end date change
  const handleEndDateChange = (e: any) => {
    setEndDate(e.target.value);
  };

  const dispatch = useDispatch<any>();
  const { employeeAttendanceDetail } = useSelector(
    (state: RootState) => state.attendance,
  );

  const getViewAttendance = useCallback(async () => {
    await dispatch(
      AttendanceAction.employeeAttendanceSummary({
        id: Number(id),
        startDate: startDate,
        endDate: endDate,
      }),
    );
  }, [id, dispatch, startDate, endDate]);

  useEffect(() => {
    getViewAttendance();
  }, [getViewAttendance, search, limit, page, startDate, endDate]);

  return (
    <WebLayout pageName={"EmployeeAttendance"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb
              mainTitle={"Attendance"}
              subTitle={"Attendance List"}
              childTitle={"View Attendance"}
            />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div
                  className="header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{
                      textTransform: "capitalize",
                      color: "#39b44b",
                      fontSize: 20,
                    }}
                  >
                    {employeeAttendanceDetail.name} Attendance <br /> Employee
                    Id - {employeeAttendanceDetail.emp_no}
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <label>From Date:</label>
                      <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        placeholder="Select start date"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid",
                          marginTop: "2 rem",
                        }}
                      />
                    </div>
                    <p className={"text-black"}>
                      <strong>&emsp;to&emsp;</strong>
                    </p>
                    <div>
                      <label>To Date:</label>
                      <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        placeholder="Select end date"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
                >
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => setLimit(e.target.value)}
                      id="defaultSelect"
                      className="form-select"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th> Punch In Time</th>
                          <th> Punch Out Time</th>
                          <th> Location</th>
                          <th> Attendance Type</th>
                          <th> Leave Type</th>
                          <th> Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeAttendanceDetail.attendance?.length > 0 ? (
                          employeeAttendanceDetail.attendance.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {moment(dataList.date).format("D-MMM-YYYY")}
                                  </td>
                                  <td>
                                    {dataList.in_time
                                      ? moment(
                                          dataList.in_time,
                                          "HH:mm",
                                        ).format("hh:mm A")
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {dataList.out_time
                                      ? moment(
                                          dataList.out_time,
                                          "HH:mm",
                                        ).format("hh:mm A")
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {dataList.in_location
                                      ? dataList.in_location
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {dataList.attendance_type
                                      ? dataList.attendance_type
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {dataList.leave_type
                                      ? dataList.leave_type
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {dataList.remarks
                                      ? dataList.remarks
                                      : "N/A"}
                                  </td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployeeAttendance;
