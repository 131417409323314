import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EmployeeAction from "../../stores/action/employeeAction";
import swal from "sweetalert";
import FilterSorting from "../../components/FilterSorting";
import Breadcrumb from "../../components/Breadcrumb";
import ImageComponent from "../../components/ImageComponent";
import TableLoader from "../../components/TableLoader";
import Pagination from "../../components/Pagination";
import BulkEmployeeModal from "../../components/ExcelUpload/BulkEmployeeModal";

interface Employee {
  id: number;
  emp_no: string;
  profile_img: string;
  name: string;
  mobile: string;
  email: string;
  present_address: string;
  sName: string;
  dName: string;
  pincode: string;
  departmentName: string;
  designationName: string;
  doj: string;
  kyc_status: string;
  account_status: string;
}

function EmployeeList() {
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const [type, setType] = useState<any>("");
  const [employeeStatus, setEmployeeStatus] = useState<any>("ACTIVE");
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const {
    employeeList = [] as Employee[], // Provide default value and type
    totalApprovedKYC,
    totalPendingKYC,
    totalSendForApprovalKYC,
    totalRejectedKYC,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
  } = useSelector((state: RootState) => state.employee);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const handleEmployeeStatus = (value: any) => {
    setEmployeeStatus(value);
  };

  const getEmployeeList = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        EmployeeAction.getEmployeeList({
          limit: limit,
          search: search,
          page: page,
          type: type,
          employeeStatus: employeeStatus,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page, type, employeeStatus]);

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList, search, limit, page, type]);

  const handleRemove = async (id: number) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "delete-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
      },
    });

    if (willDelete) {
      try {
        setLoading(true);
        await dispatch(EmployeeAction.removeEmployee({ id }));
        swal("Your data has been deleted!", {
          icon: "success",
        });
        getEmployeeList();
      } catch (error) {
        swal("Error!", "There was an issue deleting the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <WebLayout pageName={"EmployeeList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"EMA Account"}
                subTitle={"EMA Account (Employee) List"}
                firstButton={() => handleEmployeeStatus("ACTIVE")}
                secondButton={() => handleEmployeeStatus("INACTIVE")}
                haveButtons={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div
                  className="header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h2>List of all {employeeStatus.toLowerCase()} EMAs.</h2>
                    <Link to={"../create-employee"}>
                      <button className="btn btn-success btn-sm m-l-20">
                        Create EMA Account (Employee)
                      </button>
                    </Link>
                    <button
                      className="btn btn-info btn-sm m-l-20"
                      onClick={() => setModal(true)}
                    >
                      BULK UPLOAD
                    </button>

                    <BulkEmployeeModal
                      modal={modal}
                      setModal={setModal}
                      getEmployeeList={getEmployeeList}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-warning btn-sm btn-block"
                      type="button"
                      onClick={() => setType("NOT VERIFIED")}
                    >
                      <i className="fa fa-users"></i> Pending
                      <span className="badge font-bold">{totalPendingKYC}</span>
                    </button>

                    <button
                      className="btn btn-info btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("SEND FOR APPROVAL")}
                    >
                      <i className="fa fa-users"></i> Received For Approval
                      <span className="badge font-bold text-info bg-white">
                        {totalSendForApprovalKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-success btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("VERIFIED")}
                    >
                      <i className="fa fa-users"></i> Approved
                      <span className="badge font-bold">
                        {totalApprovedKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-danger btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("REJECTED")}
                    >
                      <i className="fa fa-users"></i> Rejected
                      <span className="badge font-bold">
                        {totalRejectedKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-primary btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("")}
                    >
                      <i className="fa fa-users"></i> ALL
                      <span className="badge font-bold">
                        {totalPendingKYC +
                          totalSendForApprovalKYC +
                          totalApprovedKYC +
                          totalRejectedKYC}
                      </span>
                    </button>
                  </div>
                </div>

                <FilterSorting setLimit={setLimit} setSearch={setSearch} />

                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Id</th>
                          <th> Image</th>
                          <th> Name</th>
                          <th> Communication</th>
                          <th> Address</th>
                          <th> Dept./Designation</th>
                          <th> Joining Date</th>
                          <th> KYC Status</th>
                          <th> Account Status</th>
                          <th> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={11} />
                        ) : employeeList?.length > 0 ? (
                          employeeList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <b>EMA Id :</b>{" "}
                                  {dataList.emp_no ? dataList.emp_no : "N/A"}
                                  <br />
                                  <b>ASL Id :</b>{" "}
                                  {dataList.emp_id.includes("AST")
                                    ? "N/A"
                                    : dataList.emp_id}{" "}
                                  <br />
                                  <b>EMC Employee Id :</b>{" "}
                                  {dataList.employeeId
                                    ? dataList.employeeId
                                    : "N/A"}
                                </td>
                                <td>
                                  <ImageComponent
                                    sourceImage={dataList.profile_img}
                                    width={80}
                                    height={80}
                                    altTitle={"Profile Image"}
                                  />
                                </td>
                                <td>{dataList.name}</td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Mobile :{" "}
                                  </span>{" "}
                                  {dataList.mobile}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Email :{" "}
                                  </span>{" "}
                                  {dataList.email}
                                </td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Address :{" "}
                                  </span>{" "}
                                  {dataList.present_address}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    State :{" "}
                                  </span>{" "}
                                  {dataList.sName}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    District :{" "}
                                  </span>{" "}
                                  {dataList.dName} <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    Pincode :{" "}
                                  </span>{" "}
                                  {dataList.pincode}
                                </td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Department :{" "}
                                  </span>{" "}
                                  {dataList.departmentName
                                    ? dataList.departmentName
                                    : "N/A"}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Designation :{" "}
                                  </span>{" "}
                                  {dataList.designationName
                                    ? dataList.designationName
                                    : "N/A"}
                                </td>
                                <td>{dataList.doj ? dataList.doj : "N/A"}</td>
                                <td>
                                  <button
                                    className={`btn btn-${dataList.kyc_status === "VERIFIED" ? "success" : dataList.kyc_status === "SEND FOR APPROVAL" ? "info" : dataList.kyc_status === "NOT VERIFIED" ? "warning" : "danger"} btn-sm cursor-not-allowed`}
                                  >
                                    {dataList.kyc_status === "SEND FOR APPROVAL"
                                      ? "RECEIVED FOR APPROVAL"
                                      : dataList.kyc_status === "NOT VERIFIED"
                                        ? "PENDING"
                                        : dataList.kyc_status === "VERIFIED"
                                          ? "Approved"
                                          : dataList.kyc_status}
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className={`btn btn-sm ${dataList.employee_status === "PENDING" ? "btn-warning" : dataList.employee_status === "ACTIVE" ? "btn-dark" : "btn-danger"}`}
                                    style={{ borderRadius: "0 !important" }}
                                  >
                                    {dataList.employee_status}
                                  </button>
                                </td>
                                <td>
                                  <Link
                                    to={`../employee-profile/${dataList.id}`}
                                  >
                                    <button
                                      className={"btn btn-sm btn-success"}
                                      style={{ borderRadius: "0 !important" }}
                                    >
                                      View
                                    </button>
                                  </Link>
                                  {dataList.employee_status !== "INACTIVE" && (
                                    <button
                                      className="btn btn-sm btn-danger m-l-5"
                                      onClick={() => handleRemove(dataList.id)}
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      ) : (
                                        "Remove"
                                      )}
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={11} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {employeeList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployeeList;
