import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";
import LogoutAction from "./LogoutAction";
import { getCookie } from "../../utils/CookieAction";
import DashboardType from "../type/dashboardType";

interface Action {
  type: string;
  payload?: any;
}

const DashboardAction = {
  getDepartmentCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(BASE_URL + "dashboard/department", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        });

        if (result.data.status) {
          dispatch({
            type: DashboardType.GET_DEPARTMENT_COUNT,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  getDesignationCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(
          BASE_URL + "dashboard/designation",
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: DashboardType.GET_DESIGNATION_COUNT,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  getLeaveTypeCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(BASE_URL + "dashboard/leave-type", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        });

        if (result.data.status) {
          dispatch({
            type: DashboardType.GET_LEAVE_TYPE_COUNT,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
  getHolidayCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      const token: string | any = getCookie(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.get(BASE_URL + "dashboard/holiday", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        });

        if (result.data.status) {
          dispatch({
            type: DashboardType.GET_HOLIDAY_COUNT,
            payload: result.data.data,
          });
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default DashboardAction;
