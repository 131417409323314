import React, { useEffect, useState, useCallback } from "react";
import "../../login.css";
import { EMPLOYER_USER_ID } from "../../utils/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import toast from "react-hot-toast";
import AuthAction from "../../stores/action/authAction";
import { getCookie } from "../../utils/CookieAction";

const PanelLogin: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch()<any>;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth,
  );

  const loginAccess = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const response = await dispatch(AuthAction.panelLogIn(id));
      if (response?.error) {
        toast.error(response.error);
      } else {
        toast.success("Login Successful!");
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  }, [dispatch, id, navigate]);

  useEffect(() => {
    const storedId = getCookie(EMPLOYER_USER_ID);

    let decodedId = "";
    try {
      decodedId = id ? window.atob(id) : "";
    } catch (error) {
      toast.error("Invalid login link.");
      return;
    }

    if (decodedId === storedId) {
      navigate("/dashboard"); // Directly redirect if ID matches
    } else {
      dispatch(AuthAction.logout()).finally(() => {
        if (id) loginAccess(); // Run login function after logout
      });
    }
  }, [authenticated, token, navigate, loginAccess, id, dispatch]);

  return (
    <div className="panel-login-container">
      <button
        className={`panel-login-btn ${isSubmitting ? "panel-login-disabled-btn" : ""}`}
        disabled={isSubmitting}
        onClick={loginAccess}
      >
        {isSubmitting ? <div className="loader"></div> : "Login Now"}
      </button>
    </div>
  );
};

export default PanelLogin;
