function SalaryStructureModal({
  modal,
  setModal,
  salaryData,
  salaryStructure,
  salaryPercentage,
}: any) {
  const closeModal = () => setModal(false);

  // Validate and parse salary
  const salary = parseFloat(salaryData?.attendanceSalary) || 0;
  const grossSalary = parseFloat(salaryData?.grossSalary) || 0;

  // Validate and parse salary percentages
  const basicPercentage = parseFloat(salaryPercentage?.basic_salary) || 0;
  const hraPercentage = parseFloat(salaryPercentage?.hra) || 0;
  const otherAllowancePercentage =
    parseFloat(salaryPercentage?.other_allowance) || 0;

  // Calculate Salary Components
  const basicSalary = (salary * basicPercentage) / 100;
  const hra = (salary * hraPercentage) / 100;
  const otherAllowance = (salary * otherAllowancePercentage) / 100;

  // Validate and parse salary structure
  const employeePfPercentage = parseFloat(salaryStructure?.employee_pf) || 0;
  const employerPfPercentage = parseFloat(salaryStructure?.employer_pf) || 0;
  const maxPfAmount = parseFloat(salaryStructure?.max_pf_amount) || 1800;
  const maxEsicAmount = parseFloat(salaryStructure?.max_esic_amount) || 0;
  const employeeEsicPercentage =
    parseFloat(salaryStructure?.employee_esic) || 0;
  const employerEsicPercentage =
    parseFloat(salaryStructure?.employer_esic) || 0;

  // Calculate PF with validation
  let employeePf = Math.min(
    (basicSalary * employeePfPercentage) / 100,
    maxPfAmount,
  );
  let employerPf = Math.min(
    (basicSalary * employerPfPercentage) / 100,
    maxPfAmount,
  );

  // Apply max limit of 1800 for PF
  employeePf = Math.min(employeePf, 1800);
  employerPf = Math.min(employerPf, 1800);

  // ESIC Calculation with condition
  let employeeEsic = 0;
  let employerEsic = 0;

  if (grossSalary <= maxEsicAmount) {
    employeeEsic =
      salary <= maxEsicAmount ? (salary * employeeEsicPercentage) / 100 : 0;
    employerEsic =
      salary <= maxEsicAmount ? (salary * employerEsicPercentage) / 100 : 0;

    // Apply max limit of 840 for Employee + Employer ESIC
    const totalEsic = employeeEsic + employerEsic;
    if (totalEsic > 840) {
      const ratio = 840 / totalEsic;
      employeeEsic *= ratio;
      employerEsic *= ratio;
    }
  }

  // Net Salary Calculation
  const netSalary = salary - employeePf - employeeEsic;

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={closeModal}
              style={{ background: "red" }}
            >
              <i className="fa fa-window-close" style={{ color: "white" }}></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <h4 className="modal-title text-dark text-center">
                  SALARY CALCULATION
                </h4>
                <table className="table-auto w-full border-collapse border border-gray-300">
                  <thead>
                    <tr style={{ backgroundColor: "#24245c", color: "white" }}>
                      <th className="border p-2">Component</th>
                      <th className="border p-2">Amount (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border p-2">Gross Salary</th>
                      <td className="border p-2">₹ {salary.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th className="border p-2">
                        Basic Salary ({basicPercentage}%)
                      </th>
                      <td className="border p-2">₹ {basicSalary.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th className="border p-2">HRA ({hraPercentage}%)</th>
                      <td className="border p-2">₹ {hra.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th className="border p-2">
                        Other Allowance ({otherAllowancePercentage}%)
                      </th>
                      <td className="border p-2">
                        ₹ {otherAllowance.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th className="border p-2">Employee PF (Max 1800)</th>
                      <td className="border p-2">₹ {employeePf.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th className="border p-2">Employer PF (Max 1800)</th>
                      <td className="border p-2">₹ {employerPf.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th className="border p-2">Employee ESIC</th>
                      <td className="border p-2">
                        ₹ {employeeEsic.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th className="border p-2">Employer ESIC</th>
                      <td className="border p-2">
                        ₹ {employerEsic.toFixed(2)}
                      </td>
                    </tr>
                    <tr className="font-bold bg-gray-200">
                      <th className="border p-2">Net Salary</th>
                      <td className="border p-2">₹ {netSalary.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalaryStructureModal;
